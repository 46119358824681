import type { LedgerAccountTypeEnum } from '@talos/kyoko';
import { compact } from 'lodash';
import { generatePath } from 'react-router-dom';
import type { MultilegComboType } from '../../components/MultilegCombo/enums';
import type { OrderDetailsPath, OrderRouteType } from '../Trading/Markets/OrderDetails/types';
import type { ExtractRouteParams } from './routes.types';

const DeepDiveRoutePath = '/symbol/:symbol/:id?';
export type DeepDiveRouteParams = ExtractRouteParams<typeof DeepDiveRoutePath>;
export const getDeepDiveRoute = (
  params?: DeepDiveRouteParams & { marketAccountNames?: string[]; marketNames?: string[] }
) => {
  const base = getBaseRoute('trading');
  const path = `${base}${DeepDiveRoutePath}`;
  if (params) {
    const { symbol, id, marketAccountNames, marketNames } = params;
    return `${generatePath(path, { symbol, id })}${
      marketAccountNames && marketAccountNames.length > 0 ? `?marketAccountNames=${marketAccountNames.join(',')}` : ''
    }${marketNames && marketNames.length > 0 ? `?marketNames=${marketNames.join(',')}` : ''}`;
  } else {
    return path;
  }
};

const MultilegDeepDiveRoutePath = '/trading/multileg/:instrumentType/:symbol?/:id?';
type InnerMultilegDeepDiveParams = ExtractRouteParams<typeof MultilegDeepDiveRoutePath>;
export type MultilegDeepDiveParams = InnerMultilegDeepDiveParams &
  Record<keyof Pick<InnerMultilegDeepDiveParams, 'instrumentType'>, MultilegComboType>;
export const getMultilegDeepDiveRoute = (params?: MultilegDeepDiveParams) => {
  if (params) {
    return generatePath(MultilegDeepDiveRoutePath, params);
  } else {
    return MultilegDeepDiveRoutePath;
  }
};

const ReconDetailsRoutePath = '/recon-mismatches/details/:id?';
export type ReconDetailsRouteParams = ExtractRouteParams<typeof ReconDetailsRoutePath>;
export const getReconDetailsRoute = (params?: ReconDetailsRouteParams & { mismatchIDs: string[] }) => {
  const base = getBaseRoute('trading');
  const path = `${base}${ReconDetailsRoutePath}`;
  if (params) {
    return `${generatePath(path, { id: params.id })}?${
      params.mismatchIDs ? `mismatchIDs=${params.mismatchIDs.join(',')}` : ''
    }`;
  } else {
    return path;
  }
};

const OptionsRoutePath = '/options/:underlyingCodeOrCurrency/:market/:id?';
export type OptionsRouteParams = ExtractRouteParams<typeof OptionsRoutePath>;
export const getOptionsRoute = (
  params?: OptionsRouteParams & {
    expiry?: string;
  }
) => {
  const base = getBaseRoute('trading');
  const path = `${base}${OptionsRoutePath}`;
  const searchParams = new URLSearchParams(
    compact([params?.expiry ? ['expiry', params?.expiry] : undefined])
  ).toString();
  if (params) {
    return `${generatePath(path, params)}${searchParams ? `?${searchParams}` : ''}`;
  } else {
    return path;
  }
};

const AccountLedgerEventsDetailsRoutePath = '/account-ledger-events/:id?';
export type AccountLedgerEventsDetailsRouteParams = ExtractRouteParams<typeof AccountLedgerEventsDetailsRoutePath>;
export const getAccountLedgerEventsDetailsRoute = (
  params?: AccountLedgerEventsDetailsRouteParams & {
    asset: string;
    account: string;
    accountType: LedgerAccountTypeEnum;
  }
) => {
  const base = getBaseRoute('trading');
  const path = `${base}${AccountLedgerEventsDetailsRoutePath}`;
  if (params) {
    return `${generatePath(path, { id: params.id })}?accountType=${params.accountType}&account=${
      params.account
    }&asset=${params.asset}`;
  } else {
    return path;
  }
};

const PositionAutoHedgingRulesRoutePath = '/position-auto-hedging-rules/:id?';
export type PositionAutoHedgingRulesRouteParams = ExtractRouteParams<typeof PositionAutoHedgingRulesRoutePath>;
export const getPositionAutoHedgingRulesRoute = (params?: PositionAutoHedgingRulesRouteParams) => {
  const base = getBaseRoute('trading');
  const path = `${base}${PositionAutoHedgingRulesRoutePath}`;
  if (params) {
    return generatePath(path, params);
  } else {
    return path;
  }
};

export const getOrderDetailsRoute = getOrderDetailsRouteFn('trading');
export const getMonitoringOrderDetailsRoute = getOrderDetailsRouteFn('monitoring');
export const getDealerMonitoringOrderDetailsRoute = getOrderDetailsRouteFn('dealerMonitoring');

const OrderDetailsRoutePath = `/:type/:orderID/:tab?`;
type InnerOrderDetailsRouteParams = ExtractRouteParams<typeof OrderDetailsRoutePath>;
export type OrderDetailsRouteParams =
  | InnerOrderDetailsRouteParams &
      Partial<Record<keyof Pick<InnerOrderDetailsRouteParams, 'tab'>, OrderDetailsPath>> &
      Record<keyof Pick<InnerOrderDetailsRouteParams, 'type'>, OrderRouteType>;

function getOrderDetailsRouteFn(
  context: 'trading' | 'monitoring' | 'dealerMonitoring'
): (params?: OrderDetailsRouteParams) => string {
  const base = getBaseRoute(context);
  return params => {
    const path = `${base}${OrderDetailsRoutePath}`;
    if (params) {
      return generatePath(path, params);
    } else {
      return path;
    }
  };
}

function getBaseRoute(context: 'trading' | 'monitoring' | 'dealerMonitoring') {
  if (context === 'monitoring') {
    return '/monitoring';
  }
  if (context === 'dealerMonitoring') {
    return '/dealer/monitoring';
  }
  return '/trading';
}

export const getAnalyticsOrderDetailsRoute = (params?: { orderID: string; tab?: OrderDetailsPath }) =>
  generatePath(`/analytics/order/:orderID${params?.tab == null ? '' : `/${params.tab}`}`, params);
export const getMarketRoute = (id?: string) => `/trading/${id ? id : ''}`;

export const getDataFromDeepDiveRoute = (pathname: string) => {
  // ['', 'trading', 'symbol', :symbol, :id]
  const [, , , symbol, id] = pathname.split('/');
  return [symbol, id];
};

export const getOpenTabFromOrderRoute = (pathname: string) => {
  // ['', 'trading', 'order', :id, :tab]
  const [, , , , openTab] = pathname.split('/');
  return openTab as OrderDetailsPath;
};

export const getIdFromOrderRoute = (pathname: string) => {
  // ['', 'trading', 'order', :id]
  const [, , , id] = pathname.split('/');
  return id;
};

export const getIdFromMarketRoute = (pathname: string) => {
  // ['', 'trading', :id]
  const [, , id] = pathname.split('/');
  return id;
};

export function getOpenTabFromMonitoringRoute(pathname: string) {
  // ['', 'monitoring', 'blotters', :id, :tab]
  const [, , , , openTab] = pathname.split('/');
  return openTab;
}

export function getIdFromMonitoringRoute(pathname: string) {
  // ['', 'monitoring', 'blotters', :id]
  const [, , , id] = pathname.split('/');
  return id;
}

export function getIdFromAnalyticsRoute(pathname: string) {
  // ['', 'analytics', :id]
  // ['', 'analytics', 'order', ':orderId']
  const parts = pathname.split('/');
  if (parts[2] === 'order') {
    return parts[3];
  }
  return parts[2];
}

export const isCurrentRouteOrderDetails = (pathname: string) =>
  pathname?.startsWith('/trading/order') || pathname?.startsWith('/trading/customer-order');
export const isCurrentRouteCustomerOrderDetails = (pathname: string) => pathname?.includes('/customer-order');
export const isCurrentRouteDeepDive = (pathname: string) => pathname?.startsWith('/trading/symbol');
export const isCurrentRouteReconDetails = (pathname: string) =>
  pathname.startsWith('/trading/recon-mismatches/details');
export const isCurrentRouteAccountLedgerEventsDetails = (pathname: string) =>
  pathname.startsWith('/trading/account-ledger-events');
export const isCurrentRouteMarket = (pathname: string) =>
  pathname.startsWith('/trading') && !isCurrentRouteOrderDetails(pathname) && !isCurrentRouteDeepDive(pathname);
export const isCurrentRouteSettings = (pathname: string) => pathname?.startsWith('/settings');

export const isCurrentRoutePortfolioEngineering = (pathname: string) => pathname?.startsWith('/portfolio-engineering');

export function isCurrentRouteMonitoringBlotter(pathname: string) {
  return pathname?.startsWith('/monitoring/blotters');
}

export const isCurrentRouteDealerMonitoringOrderDetails = (pathname: string) =>
  pathname?.startsWith('/dealer/monitoring/order') || pathname?.startsWith('/dealer/monitoring/customer-order');
export const isCurrentRouteMonitoringOrderDetails = (pathname: string) =>
  pathname?.startsWith('/monitoring/order') || pathname?.startsWith('/monitoring/customer-order');

export function isCurrentRouteAnalytics(pathname: string) {
  return pathname?.startsWith('/analytics') && !isCurrentRouteAnalyticsOrderDetails(pathname);
}

export const isCurrentRouteAnalyticsOrderDetails = (pathname: string) => pathname?.startsWith('/analytics/order');

export function isCurrentRouteDealer(pathname: string) {
  return pathname?.startsWith('/dealer');
}
