import {
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  BlotterDensity,
  BlotterTable,
  BlotterTableFilters,
  Box,
  Button,
  createCSVFileName,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Divider,
  Flex,
  FormControlSizes,
  HelpIcon,
  IconName,
  MarketAccountStatusEnum,
  MarketAccountTypeEnum,
  type MarketCredential,
  MixpanelEvent,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  type SubscriptionResponse,
  Toggle,
  useAccordionFilterBuilder,
  useBlotterTable,
  useConnectionStatusContext,
  useDisclosure,
  useDrawer,
  useDynamicCallback,
  useEffectOnce,
  useMarketAccountsContext,
  useMarketsContext,
  useMixpanel,
  useObservable,
  usePersistedBlotterTable,
  usePortal,
  useSyncedRef,
  useUserContext,
  VStack,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { combineLatestWith, distinctUntilChanged, map, of } from 'rxjs';
import { useFeatureFlag } from '../../../hooks';
import { useEquityMarginRatioLimitContext } from '../../../providers/EquityMarginRatioLimitProvider';
import { EditCredentialDrawerWrapper } from '../Credentials/EditCredentialDrawerWrapper';
import { useEditCredentialDrawerWrapper } from '../Credentials/useEditCredentialDrawerWrapper';
import { EquityMarginRatioDialog } from './Dialogs/EquityMarginRatioDialog';
import { useTradingControlsBlotterTableMenu } from './TradingControlsBlotterTableMenu';
import { TradingControlsDetailsDrawer } from './TradingControlsDetailsDrawer';
import type { TradingControlsBlotterEntity } from './types';
import { useAllTradableMarketAccountNames } from './useAllTradableMarketAccountNames';
import { useColumns } from './useColumns';
import { useTradingControlsFilter } from './useTradingControlsFilter';

export const TradingControlsBlotter = function TradingControlsBlotter() {
  const mixpanel = useMixpanel();
  const { connectionStatusByCredentialIDAndMarketName } = useConnectionStatusContext();
  const [marketCredentials, setMarketCredentials] = useState<MarketCredential[]>([]);
  const { listMarketCredentials } = useUserContext();
  const { marketAccountsByName } = useMarketAccountsContext();
  const marketAccountsByNameRef = useSyncedRef(marketAccountsByName);
  const { marketsByName } = useMarketsContext();
  const marketsByNameRef = useSyncedRef(marketsByName);
  const [selectedEntity, setSelectedEntity] = useState<TradingControlsBlotterEntity | undefined>();
  const { selectedCredential, credentialDrawer, handleOpenCredential } = useEditCredentialDrawerWrapper({
    marketCredentials,
  });
  const { globalEquityMarginRatioLimit, equityMarginRatioLimitByMarketAccount } = useEquityMarginRatioLimitContext();
  const allTradableMarketAccountNames = useAllTradableMarketAccountNames();
  const equityMarginRatioDialog = useDisclosure();
  const { enableTradingControlsDefaultSettings } = useFeatureFlag();

  const marketCredentialsByCredentialID = useMemo(
    () => new Map(marketCredentials.map(credential => [credential.CredentialID, credential])),
    [marketCredentials]
  );

  const detailsDrawer = useDrawer({
    position: 'relative',
    width: 420,
    placement: 'right',
  });
  const handleOpenCredentialDrawer = useDynamicCallback((credentialID: number) => {
    mixpanel.track(MixpanelEvent.ViewCredential);
    detailsDrawer.close();
    handleOpenCredential(credentialID);
  });

  const handleEdit = useDynamicCallback((entity: TradingControlsBlotterEntity) => {
    setSelectedEntity(entity);
    credentialDrawer.close();
    detailsDrawer.open();
  });

  const defaultColumns = useColumns({
    handleEdit,
    handleOpenCredential: handleOpenCredentialDrawer,
  });

  const persistedBlotterTable = usePersistedBlotterTable<TradingControlsBlotterEntity>('trading-controls', {
    columns: defaultColumns,
    sort: '+Market',
  });

  const filterResults = useTradingControlsFilter({
    persistedBlotterTable,
  });
  const { clientSideFilter: clientLocalFilter, filterBuilderProps, filter, changeFilter } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    filterBuilderProps,
  });

  const handleShowInactiveMarketAccounts = useDynamicCallback((checked: boolean) => {
    changeFilter(curr => ({
      ...curr,
      ShowInactiveMarketAccounts: checked,
    }));
  });

  const blotterTableMenu = useTradingControlsBlotterTableMenu({
    onEdit: handleEdit,
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...blotterTableMenu.columns],
    [persistedBlotterTable.columns, blotterTableMenu.columns]
  );

  const dataObservable = useObservable<SubscriptionResponse<TradingControlsBlotterEntity>>(
    () =>
      of(allTradableMarketAccountNames).pipe(
        distinctUntilChanged(),
        combineLatestWith(connectionStatusByCredentialIDAndMarketName),
        map(([marketAccountNames, statuses]) => {
          const hydratedData: TradingControlsBlotterEntity[] = compact(
            marketAccountNames.map(marketAccountName => {
              const marketAccount = marketAccountsByNameRef.current.get(marketAccountName);
              if (!marketAccount || marketAccount.Type !== MarketAccountTypeEnum.Trading) {
                return null;
              }
              if (!filter.ShowInactiveMarketAccounts && marketAccount.Status === MarketAccountStatusEnum.Inactive) {
                return null;
              }
              const market = marketsByNameRef.current.get(marketAccount.Market);
              return {
                ...marketAccount,
                RowID: `${marketAccount.MarketAccountID}`,
                MarketType: market?.Type,
                ConnectionStatus: statuses?.get(marketAccount.CredentialID)?.get(marketAccount.Market)?.Status,
                EquityMarginRatio: equityMarginRatioLimitByMarketAccount.get(marketAccount.Name)?.Ratio,
                GlobalEquityMarginRatio: globalEquityMarginRatioLimit?.Ratio,
                MarketCredential: marketCredentialsByCredentialID.get(marketAccount.CredentialID),
              };
            })
          );
          return { data: hydratedData, initial: true, type: 'MarketAccount', ts: '0' };
        })
      ),
    [
      filter.ShowInactiveMarketAccounts,
      allTradableMarketAccountNames,
      connectionStatusByCredentialIDAndMarketName,
      marketAccountsByNameRef,
      marketsByNameRef,
      marketCredentialsByCredentialID,
      globalEquityMarginRatioLimit,
      equityMarginRatioLimitByMarketAccount,
    ]
  );

  const blotterTable = useBlotterTable<TradingControlsBlotterEntity>({
    dataObservable,
    rowID: 'RowID',
    density: BlotterDensity.Comfortable,
    sort: persistedBlotterTable.initialSort,
    clientLocalFilter,
    onSortChanged: persistedBlotterTable.onSortChanged,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    columns: columnsWithMenu,
    onDoubleClickRow: handleEdit,
    getContextMenuItems: blotterTableMenu.getContextMenuItems,
    selection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  });

  const handleExport = useCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Trading Controls',
      }),
    });
  }, [blotterTable, mixpanel]);

  const handleOpenEquityMarginRatioDialog = useCallback(() => {
    mixpanel.track(MixpanelEvent.ViewTradingControlDefaultSettings);
    equityMarginRatioDialog.open();
  }, [equityMarginRatioDialog, mixpanel]);

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  const refreshData = useDynamicCallback(() => {
    listMarketCredentials().then(credentials => {
      setMarketCredentials(credentials);
    });
  });

  useEffectOnce(() => {
    refreshData();
  });

  return (
    <>
      <Panel>
        <PanelHeader pb="0">
          <VStack w="100%" gap="spacingSmall" alignItems="flex-start" whiteSpace="break-spaces">
            <h2>Trading Controls</h2>
            <span>
              {enableTradingControlsDefaultSettings && (
                <>
                  Enable or disable trading for market accounts and configure Equity Margin Ratio Limit check for
                  derivatives trading. <HelpIcon tooltip={TradingControlsTooltip} />
                </>
              )}
              {!enableTradingControlsDefaultSettings && (
                <>
                  Enable or disable trading for market accounts. When trading is disabled, balances will continue to
                  update and market data will stream. Market data is not considered firm and orders will not be sent to
                  the market account.
                </>
              )}
            </span>
            <Flex w="100%" justifyContent="flex-end" py="spacingDefault">
              <PanelActions>
                <Toggle
                  size={FormControlSizes.Small}
                  checked={filter.ShowInactiveMarketAccounts ?? false}
                  onChange={handleShowInactiveMarketAccounts}
                  label="Show Inactive Market Accounts"
                />
                <Box ref={filtersContainerRef} />
                <Button startIcon={IconName.DocumentDownload} onClick={handleExport}>
                  Export CSV
                </Button>
                {enableTradingControlsDefaultSettings && (
                  <>
                    <Divider orientation="vertical" />
                    <Button startIcon={IconName.Cog} onClick={handleOpenEquityMarginRatioDialog}>
                      Default Settings
                    </Button>
                  </>
                )}
              </PanelActions>
            </Flex>
          </VStack>
        </PanelHeader>
        <PanelContent>
          <BlotterTableFilters
            {...filterBuilderAccordion}
            {...blotterTable.blotterTableFiltersProps}
            size={FormControlSizes.Default}
          />
          <BlotterTable {...blotterTable} />
        </PanelContent>
      </Panel>
      <TradingControlsDetailsDrawer
        {...detailsDrawer}
        selectedEntity={selectedEntity}
        marketCredentials={marketCredentials}
        handleOpenCredential={handleOpenCredential}
        data-testid="details-drawer"
      />
      <EditCredentialDrawerWrapper
        {...credentialDrawer}
        marketCredentials={marketCredentials}
        selectedCredential={selectedCredential}
        onSaved={refreshData}
        data-testid="credential-drawer"
      />
      <EquityMarginRatioDialog dialog={equityMarginRatioDialog} />
      {blotterTableMenu.dialogComponents}
    </>
  );
};

const TradingControlsTooltip = (
  <>
    <p style={{ marginTop: 0 }}>
      When trading is disabled, balances will continue to update and market data will stream. Market data is not
      considered firm and orders will not be sent to the market account.
    </p>
    <p>
      The Equity Margin Ratio Limit allows an organization to reduce risk associated with derivatives trading by
      enforcing their leverage limits across different trading platforms. The specified default EMR Limit can be
      overwritten at a market account level.
    </p>
  </>
);
