import { createDevTools } from '@redux-devtools/core';
import { DockMonitor } from '@redux-devtools/dock-monitor';
import { SliderMonitor } from '@redux-devtools/slider-monitor';
import { configureStore, createListenerMiddleware, type UnknownAction } from '@reduxjs/toolkit';
import type { IWebSocketClient, User } from '@talos/kyoko';
import type { Equal, Expect } from '@talos/kyoko/src/tests';
import {
  portfolioViewLayoutSlice,
  setupListeners as setupPortfolioListeners,
} from 'containers/Portfolio/PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import { initialValues } from 'providers/AppConfigProvider/initialValues';
import { createAppConfigSlice } from 'providers/AppConfigReduxProvider/AppConfigSlice';
import { useRef, type PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { ReplaySubject } from 'rxjs';
import { appLayoutSlice } from '../../components/AppLayout/AppLayoutSlice';
import { multilegComboSlice } from '../../components/MultilegCombo/MultilegComboSlice';
import { activeOrderCardsSlice } from '../../components/OMS/Cards/ActiveOrderCardsSlice';
import {
  careOrderSlice,
  setupListeners as setupCareOrderFormListeners,
} from '../../components/OMS/CareOrderFormView/CareOrderSlice';
import { detailsDrawerSlice } from '../../components/OMS/Details/DetailsDrawerSlice';
import { manualOrderFillSlice } from '../../components/OMS/ManualOrderFillView/ManualOrderFillSlice';
import { manualRFQPricingSlice } from '../../components/OMS/ManualRFQPricingView/ManualRFQPricingSlice';
import { manualTradeSlice } from '../../components/OMS/ManualTradeView/ManualTradeSlice';
import { orderSlice } from '../../components/OMS/NewOrder/OrderSlice';
import { rfqSlice, setupListeners as setupRFQSliceListeners } from '../../components/OMS/NewRFQ/RFQSlice';
import { referenceDataSlice } from '../../components/OMS/OMSReferenceDataSlice';
import { OMSSlice } from '../../components/OMS/OMSSlice';
import { orderPresetSlice } from '../../components/OMS/Presets/OrderPresetsSlice';
import { salesOrderSlice } from '../../components/OMS/SalesOrder/SalesOrderSlice';
import { salesRFQSlice } from '../../components/OMS/SalesRFQView/SalesRFQSlice';
import { getWsClient } from '../../ws';
import { AppStateContext } from './AppStateContext';
import { setupListeners as setupStreamingDataListeners, streamingDataSlice } from './streamingDataSlice';
import { buildAppStateListener, type AppState } from './types';

/**  This subject will allow us to create a pattern similar to redux-observable (powerful middleware leveraging rxjs)
 * With this we are able to observe all actions going into this store. Our services can now create custom observables that react to this
 * See OrderService.observeForMarginCost as example.
 *
 * IMPORTANT: See deprecation note with {@link appStateActionsStream}
 *
 * HACK: Setting replay subject to buffer=2 to avoid missing actions when the reader is watching this stream late,
 * as needed by SalesOrderService.
 */
const appStateActionsSubject: ReplaySubject<UnknownAction> = new ReplaySubject(2);
/** Stream of App State actions
 *
 * @deprecated
 * USE WITH CARE: Make sure your reader is watching this stream early enough to not
 * miss any actions, since this is a hot observable.
 *
 * (Consider implementing a Redux Toolkit middleware so that the dispatch order doesn't matter, and the behavior
 * works directly against the state) */
export const appStateActionsStream = appStateActionsSubject.asObservable();

// It does nothing other than proxying the action into our Actions Subject
// Note: Subject receives action after the Store, again same as redux-observable
const customMiddleware = store => next => action => {
  next(action);
  appStateActionsSubject.next(action);
};

const isLocal = import.meta.env.VITE_AVA_ENV === 'local';
const isReduxDevToolsEnabled = isLocal && import.meta.env.VITE_AVA_REDUXDEVTOOLS === 'true';

const DevTools = createDevTools(
  <DockMonitor
    toggleVisibilityKey="ctrl-h"
    changePositionKey="ctrl-q"
    changeMonitorKey="ctrl-e"
    defaultIsVisible={false}
  >
    {/* InspectorMonitor package is bundled as CJS and Vite does not support CJS anymore - uncomment when needing locally */}
    {/* <InspectorMonitor theme="google" /> */}
    <SliderMonitor keyboardEnabled />
  </DockMonitor>
);

const DevToolsWrapper = ({ store }) => {
  return isLocal && isReduxDevToolsEnabled ? <DevTools store={store} /> : null;
};

export const layoutListenerMiddleware = createListenerMiddleware<AppState>();

export type AppStateStore = ReturnType<typeof getAppStateStore>;

export const getAppStateStore = ({
  wsClient,
  appConfigSlice,
}: {
  wsClient?: IWebSocketClient<unknown>;
  appConfigSlice: ReturnType<typeof createAppConfigSlice>;
}) => {
  const listenerMiddleware = createListenerMiddleware({
    extra: {
      wsClient: wsClient ?? getWsClient(),
    },
  });

  const startListening = buildAppStateListener(listenerMiddleware);

  setupStreamingDataListeners(startListening);
  setupPortfolioListeners(startListening);
  setupRFQSliceListeners(startListening);
  setupCareOrderFormListeners(startListening);

  return configureStore({
    reducer: {
      appConfig: appConfigSlice.reducer,
      appLayout: appLayoutSlice.reducer,
      cards: activeOrderCardsSlice.reducer,
      careOrder: careOrderSlice.reducer,
      detailsDrawer: detailsDrawerSlice.reducer,
      manualOrderFill: manualOrderFillSlice.reducer,
      manualRFQPricing: manualRFQPricingSlice.reducer,
      manualTrade: manualTradeSlice.reducer,
      multilegCombo: multilegComboSlice.reducer,
      OMS: OMSSlice.reducer,
      order: orderSlice.reducer,
      portfolioViewLayout: portfolioViewLayoutSlice.reducer,
      presets: orderPresetSlice.reducer,
      referenceData: referenceDataSlice.reducer,
      streamingData: streamingDataSlice.reducer,
      rfq: rfqSlice.reducer,
      salesOrder: salesOrderSlice.reducer,
      salesRFQ: salesRFQSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
        thunk: {
          extraArgument: {
            wsClient: wsClient ?? getWsClient(),
          },
        },
      }).concat(
        streamingDataSlice.middleware,
        listenerMiddleware.middleware,
        layoutListenerMiddleware.middleware,
        customMiddleware
      ),
    enhancers: getDefaultEnhancers => {
      const enhancers = getDefaultEnhancers();
      if (isReduxDevToolsEnabled) {
        enhancers.push(DevTools.instrument());
      }
      return enhancers;
    },

    // https://github.com/reduxjs/redux-devtools/issues/643
    // The chrome devtools runs in a separate process as the app/browser tab, this means that all actions and state
    // needs to be serialized in order for the extension to be able to read it
    // OMS have references to big payloads and state object meaning that the extension will run very slow / unusable
    // but fast as if we run it as a native component (DevTools)
    devTools: false,
  });
};

export const AppStateProvider = ({
  children,
  user,
}: PropsWithChildren<{
  user: Pick<User, 'AppConfig'>;
}>) => {
  const storeRef = useRef<AppStateStore | null>(null);
  if (!storeRef.current) {
    const appConfig = initialValues(JSON.parse(user.AppConfig || '{}'));
    storeRef.current = getAppStateStore({
      appConfigSlice: createAppConfigSlice(appConfig),
    });
  }
  return (
    <Provider context={AppStateContext} store={storeRef.current}>
      <DevToolsWrapper store={storeRef.current} />
      {children}
    </Provider>
  );
};

export { AppStateContext, useAppStateDispatch, useAppStateSelector, useAppStateStore } from './AppStateContext';

type Store = ReturnType<ReturnType<typeof getAppStateStore>['getState']>;
// TYPE LEVEL TESTS
type _Expect_Store_State_To_Match_RootState = Expect<Equal<AppState, Store>>;
