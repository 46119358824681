import { Box, Flex, VStack } from '@talos/kyoko';
import DockViewProvider, { type DockViewProviderType } from 'components/FlexibleLayout/DockViewProvider';

import { ErrorBoundary } from 'components/ErrorBoundary';
import type { SerializedDockview } from 'dockview';
import { useRollupTreeList } from 'hooks';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { ContextBlotterFilterProvider } from 'providers/ContextBlotterFilterProvider';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { DockViewWrapper } from './components/DockViewWrapper';
import { getPortfolioLayoutOptions } from './layoutConfiguration/getPortfolioLayoutOptions';
import { LAYOUT_OPTIONS_FIXED_COMPONENT_FACTORY } from './layoutConfiguration/layoutOptionsComponentFactory';
import { PortfolioDashboardHeader } from './PortfolioDashboardHeader';
import type { PortfolioViewType } from './portfolioDashboardLayouts';
import { PortfolioWorkspaceHeaderMenuPortal } from './PortfolioWorkspaceHeaderMenu/PortfolioWorkspaceHeaderMenuPortal';
import { PortfolioManagementProvider } from './providers/PortfolioManagementProvider';
import { getDefaultLayoutForViewTab, getLayoutForViewTab } from './stateManagement/portfolioViewLayoutSlice';
import {
  getPortfolioViewActions,
  usePortfolioViewRouteStateSelector,
} from './stateManagement/portfolioViewLayoutSlice.hooks';
import { usePortfolioRouting } from './usePortfolioRouting';

const useValidatePMSRoute = (): boolean => {
  const location = useLocation();
  const tabRoute = location.pathname.split('/').pop();
  const viewRoute = location.pathname.split('/')[2];
  const selectedView = getPortfolioLayoutOptions().find(option => option.route === `/${viewRoute}`);
  const isRouteValid = Boolean(selectedView?.tabs.find(tab => tab.route === `/${tabRoute}`));
  return isRouteValid;
};

export const PortfolioDashboard = () => {
  const isValidRoute = useValidatePMSRoute();
  return !isValidRoute ? <div data-testid="portfolio-dashboard-invalid-route" /> : <PortfolioDashboardInner />;
};

const { changeSelectedPortfolioId, updateLayout, changeView } = getPortfolioViewActions();
const PortfolioDashboardInner = () => {
  const { userLayoutMapForViewTab } = usePortfolioViewRouteStateSelector();
  const { selectedView, selectedTab } = usePortfolioRouting();
  const rollupTreeList = useRollupTreeList();
  const dispatch = useAppStateDispatch();
  useEffect(() => {
    if (selectedView) {
      dispatch(changeView(selectedView.value));
    }
  }, [dispatch, selectedView]);

  const isFirstRunDone = useRef(false);
  useEffect(() => {
    if (!isFirstRunDone.current && rollupTreeList.length > 0) {
      dispatch(
        changeSelectedPortfolioId({
          selectedPortfolioId: rollupTreeList[0].subAccountId,
          onlyIfNotSet: true,
        })
      );
    }
  }, [dispatch, rollupTreeList]);

  const updateViewAndLayout = useCallback(
    ({ userLayout }: { userLayout: SerializedDockview }) => {
      dispatch(
        updateLayout({
          view: selectedView.value,
          tabLabel: selectedTab.label,
          userLayout,
        })
      );
    },
    [dispatch, selectedTab.label, selectedView.value]
  );

  const getLayoutForViewTabCallback: DockViewProviderType<PortfolioViewType>['getLayoutForView'] = useCallback(
    (view, tabLabel) => getLayoutForViewTab(userLayoutMapForViewTab, view, tabLabel),
    [userLayoutMapForViewTab]
  );

  const getDefaultLayoutForViewTabCallback: DockViewProviderType<PortfolioViewType>['getDefaultLayoutForView'] =
    useCallback((view, tabLabel) => getDefaultLayoutForViewTab(view, tabLabel), []);

  const activeFilters = useMemo(() => {
    const activeFilters = selectedTab.activeFilters ?? [];
    const activeControls = selectedTab.activeControls?.menuOptions ?? [];
    return [...activeFilters, ...activeControls] ?? [];
  }, [selectedTab?.activeControls?.menuOptions, selectedTab?.activeFilters]);

  return (
    <VStack w="100%" h="100%" alignItems="stretch" gap="spacingTiny" data-testid="portfolio-dashboard">
      <PortfolioDashboardHeader />
      <ErrorBoundary key={`${selectedView.label}-${selectedTab.label}`}>
        <Flex flex="auto" flexDirection="column" display="flex" w="100%" data-testid="portfolio-dashboard-tab-view">
          <PortfolioManagementProvider>
            <ContextBlotterFilterProvider filterKeys={activeFilters}>
              {selectedTab.layoutType === 'fixed' ? (
                <>
                  <PortfolioWorkspaceHeaderMenuPortal />
                  <Box position="relative" w="100%" h="100%">
                    <Box position="absolute" top="0" left="0" right="0" bottom="0">
                      {LAYOUT_OPTIONS_FIXED_COMPONENT_FACTORY[selectedTab.content]}
                    </Box>
                  </Box>
                </>
              ) : (
                <DockViewProvider<PortfolioViewType>
                  view={selectedView.value}
                  tabLabel={selectedTab.label}
                  getLayoutForView={getLayoutForViewTabCallback}
                  getDefaultLayoutForView={getDefaultLayoutForViewTabCallback}
                  updateLayout={updateViewAndLayout}
                >
                  <PortfolioWorkspaceHeaderMenuPortal />
                  <DockViewWrapper />
                </DockViewProvider>
              )}
            </ContextBlotterFilterProvider>
          </PortfolioManagementProvider>
        </Flex>
      </ErrorBoundary>
    </VStack>
  );
};
