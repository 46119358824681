import {
  Box,
  CurrencyIcon,
  DepthTypeEnum,
  Divider,
  EMPTY_ARRAY,
  FeeModeEnum,
  HStack,
  Icon,
  IconName,
  InlineFormattedNumber,
  Input,
  LiquidityTypeEnum,
  MARKET_DATA_SNAPSHOT,
  Text,
  VStack,
  useMarketDataStatistics,
  useObservableValue,
  useSecuritiesContext,
  useSubscription,
  type MarketDataSnapshot,
} from '@talos/kyoko';
import { isNil } from 'lodash';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import type { AppState } from 'providers/AppStateProvider/types';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { memo, useMemo } from 'react';
import { map } from 'rxjs/operators';
import { useTheme } from 'styled-components';
import { updateEditableProperty } from './MultilegComboSlice';
import { isMultilegOptionComboType, type MultilegComboType } from './enums';

export interface TopInfoProps {
  instrumentType: MultilegComboType | undefined;
  symbol: string | undefined;
}

export const MultilegComboHeader = memo(({ symbol, instrumentType }: TopInfoProps) => {
  const { securitiesBySymbol } = useSecuritiesContext();
  const { showAllInPrices, showFirmLiquidity } = useDisplaySettings();
  const theme = useTheme();

  const isEditing = useAppStateSelector((state: AppState) => state.multilegCombo.isEditing);
  const security = symbol ? securitiesBySymbol.get(symbol) : undefined;

  const { marketDataStatistics } = useMarketDataStatistics(symbol || '', [], 'MultilegDeepdive');
  const dispatch = useAppStateDispatch();
  const displaySymbol = useAppStateSelector((state: AppState) => state.multilegCombo.editableProperties?.displaySymbol);

  const snapshotRequest = useMemo(
    () =>
      symbol
        ? {
            name: MARKET_DATA_SNAPSHOT,
            tag: 'MultilegComboHeader',
            DepthType: DepthTypeEnum.Price,
            Markets: EMPTY_ARRAY,
            Symbol: symbol,
            LiquidityType: showFirmLiquidity ? LiquidityTypeEnum.Firm : LiquidityTypeEnum.Indicative,
            UnifiedLiquidity: 'Disabled',
            Depth: 1,
            FeeMode: showAllInPrices ? FeeModeEnum.Taker : undefined,
          }
        : null,
    [symbol, showAllInPrices, showFirmLiquidity]
  );
  const { data: snapshotsSubscription } = useSubscription<MarketDataSnapshot>(snapshotRequest);
  const snapshotData = useObservableValue(
    () => snapshotsSubscription.pipe(map(snapshot => snapshot.data?.[0])),
    [snapshotsSubscription]
  );

  const isOption = useMemo(() => isMultilegOptionComboType(instrumentType), [instrumentType]);

  const greeks = useMemo(() => {
    if (isOption) {
      return (
        <>
          <InfoBox
            label="Delta"
            value={marketDataStatistics?.Symbol === symbol ? marketDataStatistics?.Delta : undefined}
          />
          <InfoBox
            label="Gamma"
            value={marketDataStatistics?.Symbol === symbol ? marketDataStatistics?.Gamma : undefined}
          />
          <InfoBox
            label="Vega"
            value={marketDataStatistics?.Symbol === symbol ? marketDataStatistics?.Vega : undefined}
          />
          <InfoBox
            label="Theta"
            value={marketDataStatistics?.Symbol === symbol ? marketDataStatistics?.Theta : undefined}
          />
        </>
      );
    }
    return null;
  }, [symbol, marketDataStatistics, isOption]);

  // Temporarily hiding until back end ready
  // const highLowVolume = useMemo(() => {
  //   if (
  //     security &&
  //     (instrumentType === MultilegComboType.Delta1Spread ||
  //       instrumentType === MultilegComboType.SyntheticCross)
  //   ) {
  //     return <SparklineSummary security={security} exchangeMarkets={EMPTY_ARRAY} />;
  //   }
  //   return null;
  // }, [instrumentType, security]);

  return (
    <HStack justifyContent="space-between" h={48} flex="0 0 auto">
      <HStack justifyContent="space-between" gap="spacingDefault" color="colorTextImportant">
        <CurrencyIcon currency={security?.BaseCurrency || 'BTC'} size={24} />
        {isEditing ? (
          <Box w="300px">
            <Input
              value={displaySymbol ?? security?.DisplaySymbol}
              data-testid="multileg-display-symbol-input"
              onChange={e =>
                isEditing ? dispatch(updateEditableProperty({ key: 'displaySymbol', value: e.target.value })) : null
              }
              suffix={<Icon icon={IconName.Pencil} />}
            />
          </Box>
        ) : (
          <Text>{security?.DisplaySymbol || '--'}</Text>
        )}
        {/*{highLowVolume}*/}
      </HStack>
      <HStack justifyContent="space-between">
        <Divider color={theme.colors.gray['000']} />
        <InfoBox label="Bid" value={snapshotData?.Symbol === symbol ? snapshotData?.Bids?.[0]?.Price : undefined} />
        {isOption && (
          <InfoBox
            label="Mark"
            value={marketDataStatistics?.Symbol === symbol ? marketDataStatistics?.MarkPrice : undefined}
          />
        )}
        <InfoBox label="Offer" value={snapshotData?.Symbol === symbol ? snapshotData?.Offers?.[0]?.Price : undefined} />
        {greeks}
      </HStack>
    </HStack>
  );
});

const InfoBox = ({ label, value }) => {
  return (
    <VStack alignItems="start" ml="spacingLarge">
      <Text size="fontSizeTiny" color="colorTextSubtle" transform="uppercase">
        {label}
      </Text>
      <Text weight="fontWeightMedium" data-testid="24high">
        {isNil(value) ? '--' : <InlineFormattedNumber number={value} />}
      </Text>
    </VStack>
  );
};
