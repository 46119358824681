import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Box,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  DEFAULT_MAX_ROWS,
  FormControlSizes,
  IconName,
  MARKET_ORDER,
  MarketOrder,
  MixpanelEvent,
  WSBlotterTableMaxRecordsReachedWarning,
  columnToColumnState,
  createCSVFileName,
  filterByColumnMainMenuItems,
  isTalosUser,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useMarketOrderColumns,
  useMixpanel,
  usePersistedBlotterTable,
  useUserContext,
  useWsBlotterTable,
  type BlotterTableFilter,
  type BlotterTableSort,
  type Column,
  type ColumnState,
} from '@talos/kyoko';
import type { GetMainMenuItemsParams } from 'ag-grid-community';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import { useMemo } from 'react';
import { useMarketOrdersMenu } from './MarketOrdersMenu';
import {
  colIDToFilterBuilderKey,
  onlyServerFilterKeys,
  useMarketOrderFilter,
  type MarketOrderFilter,
} from './useMarketOrderFilter';

export interface MarketOrdersBlotterTableProps {
  /** Unique id per blotter instance */
  blotterID: string;
  /** ID for common blotter type persistance; usually a subset of the blotterId to represent blotter area 'type' */
  blotterPersistID?: string;
  tabLabel?: string;
  orderID?: string;
  rfqID?: string;
  parentOrderID?: string;
  parentRFQID?: string;

  defaultColumns?: (Column | keyof MarketOrder)[];
  defaultFilter?: MarketOrderFilter;
  defaultSort?: BlotterTableSort<MarketOrder>;
  persistColumns?: boolean;
  persistFilter?: boolean;
  persistSort?: boolean;
  onCloneTab?: (filter: BlotterTableFilter, columns: ColumnState[]) => void;
  initialIsOpen?: boolean;
  source?: 'trading' | 'monitoring' | 'dealer-monitoring';
}

export function MarketOrdersBlotterTable({
  parentOrderID,
  parentRFQID,
  blotterID,
  blotterPersistID = blotterID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  defaultSort = '-SubmitTime',
  persistFilter,
  persistColumns,
  persistSort,
  onCloneTab,
  initialIsOpen,
  source,
}: MarketOrdersBlotterTableProps) {
  const { getConfig } = useOrgConfiguration();
  const mixpanel = useMixpanel();
  const columnDefinitions = useMarketOrderColumns({ defaultColumns });

  const persistedBlotterTable = usePersistedBlotterTable(blotterPersistID, {
    columns: columnDefinitions,
    filter: defaultFilter,
    sort: defaultSort,
    persistColumns,
    persistFilter,
    persistSort,
  });

  const marketOrderFilter = useMarketOrderFilter({
    persistedBlotterTable,
    includeStatusFilter: true,
    includeIDFilter: parentOrderID == null && parentRFQID == null,
  });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = marketOrderFilter;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });
  const marketOrderMenu = useMarketOrdersMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...marketOrderMenu.columns],
    [marketOrderMenu.columns, persistedBlotterTable.columns]
  );
  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    return filterByColumnMainMenuItems({
      params,
      colIDToFilterBuilderKey,
      openClause: filterBuilderAccordion.openClause,
      mixpanel,
    });
  });

  const { user } = useUserContext();

  // This call owns the true filter state
  const blotterTable = useWsBlotterTable({
    initialRequest: {
      name: MARKET_ORDER,
      tag: blotterID,
      ...(parentOrderID != null && { ParentOrderID: parentOrderID }),
      ...(parentRFQID != null && { ParentRFQID: parentRFQID }),
    },
    filter: onlyServerFilterKeys(marketOrderFilter.filter),
    initialSort: persistedBlotterTable.initialSort,
    rowID: MarketOrder.rowID,
    selection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
    clientLocalFilter,
    startingRowLimit: getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS),
    columns: columnsWithMenu,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    getContextMenuItems: marketOrderMenu.getContextMenuItems,
    getExtraMainMenuItems,
    pauseParams: {
      showPauseButton: isTalosUser(user),
    },
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(marketOrderFilter.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'MarketOrders',
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });

  return (
    <Box h="100%" data-testid="market-orders-blotter">
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        canSubmitClearedDateRange={source === 'trading' ? true : false}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.SubmitTime} />
      <BlotterTable {...blotterTable} />
      {marketOrderMenu.dialogs}
    </Box>
  );
}
