import {
  ACTION,
  CopyCsv,
  CopyJson,
  Divider,
  IconName,
  LedgerAccountTypeEnum,
  MixpanelEventSource,
  PositionUpdateSourceEnum,
  ShowJson,
  filterByCellValueMenuItem,
  getRowNodesToOperateOn,
  selectAll,
  useDisclosure,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  useSyncedRef,
  type ColumnDef,
  type Exposure,
  type FilterableProperty,
  type Position,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, ICellRendererParams } from 'ag-grid-community';
import { compact, isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { PositionTypeEnum, useFeatureFlag, useRoleAuth } from '../../../hooks';
import {
  pinClosePositionColumnColDefParams,
  useTradePositionColumn,
} from '../../../utils/columns/useTradePositionColumn';
import { BulkCloseDialog } from './BulkClosePosition/BulkCloseDialog';

import { useNavigateToAccountLedgerEvents } from '../../Trading/Markets/AccountLedgerEvents/useNavigateToAccountLedgerEvents';
import { useGetBulkClosablePositions, type ClosablePosition } from './BulkClosePosition/useGetBulkClosablePositions';
import { UpdatePositionsDialog } from './UpdatePositionsDialog';
import { colIDToFilterBuilderKey, type PositionsTabType } from './types';

export function PositionMenu(
  params: ICellRendererParams & {
    onShowJson(data: Exposure): void;
  }
) {
  return (
    <>
      <ShowJson {...params} />
      <Divider orientation="horizontal" />
      <CopyJson {...params} />
      <CopyCsv {...params} />
    </>
  );
}

interface UsePositionsMenuParams {
  openClause: UseFilterBuilderOutput['addAndOpenClause'] | undefined;
  filterableProperties: FilterableProperty<string>[];
  enableBulkClose?: boolean;
  tabType: PositionsTabType;

  /**
   * An optional callback you can implement in order to take control of the resolving of a right-click in a column
   * to the colId that should be used for filtering on the right-clicked column.
   *
   * The provided function returning undefined is effectively the same as the function itself not being provided -- column filtering
   * fallbacks to its standard code path.
   *
   * This is needed for supporting Tree Blotters.
   */
  getColIdForFiltering?: (params: GetContextMenuItemsParams) => string | undefined;
  /**
   * Whether or not to show the Open transaction history action button in the context menu. Defaults to false.
   */
  showOpenTransactionHistoryMenuItem?: boolean;
}

export function usePositionsMenu({
  openClause,
  filterableProperties,
  enableBulkClose = false,
  tabType,
  getColIdForFiltering,
  showOpenTransactionHistoryMenuItem,
}: UsePositionsMenuParams) {
  const bulkClosePositionsDialog = useDisclosure();
  const mixpanel = useMixpanel();
  const { handleClickJson, jsonModal } = useJsonModal();

  const { isAuthorized } = useRoleAuth();
  const authorizedToTrade = isAuthorized(ACTION.SUBMIT_ORDER);
  const authorizedToEditPosition = isAuthorized(ACTION.EDIT_POSITION);

  const { enableBulkClosePosition: enableBulkClosePositionFeatureFlag, enableAccountLedgerEventsPage } =
    useFeatureFlag();
  const showBulkClose = enableBulkClose && enableBulkClosePositionFeatureFlag;

  const { getBulkClosablePositions } = useGetBulkClosablePositions();
  // We make this function stable so we dont do too much work and risk changing the suggested SendOrderArgs returned mid-confirmation.
  const stableGetBulkClosablePositions = useSyncedRef(getBulkClosablePositions);

  // State representing the positions set to be closed as their respective SendOrderArgs action representations
  const [closablePositions, setClosablePositions] = useState<ClosablePosition[]>([]);

  const [editPositionPrimer, setEditPositionPrimer] = useState<Position>();
  const updatePositionsDialog = useDisclosure({ onClose: () => setEditPositionPrimer(undefined) });
  const handleUpdatePositions = useDynamicCallback((primer?: Position) => {
    setEditPositionPrimer(primer);
    updatePositionsDialog.open();
  });

  const openBulkClosePositionDialog = useDynamicCallback((closablePositions: ClosablePosition[]) => {
    setClosablePositions(closablePositions);
    bulkClosePositionsDialog.open();
  });
  const navigateToAccountLedgerEvents = useNavigateToAccountLedgerEvents();
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<Position>) => {
    const newSelectedPositions = compact(getRowNodesToOperateOn(params).map(node => node.data));

    const data = params?.node?.data;
    const groupData = params?.node?.groupData;
    if (isNil(data) && isNil(groupData)) {
      return [];
    }

    const items = [
      ...(data
        ? [
            {
              icon: `<i class="ag-icon ${IconName.Braces}"/>`,
              name: `Show JSON`,
              action: () => handleClickJson(data),
            },
            'separator',
          ]
        : []),
      selectAll(params, mixpanel),
      'separator',
      ...getDefaultContextMenuItems(params),
    ];

    if (openClause) {
      // if a manual resolver is passed, use it. if not, colID is undefined, which will cause filterByCellValueItem to do its default thing
      const colID = getColIdForFiltering?.(params);

      items.unshift(
        ...filterByCellValueMenuItem({
          params,
          filterableProperties,
          openClause,
          colIDToFilterBuilderKey,
          mixpanel,
          colID,
        })
      );
    }

    if (showBulkClose && authorizedToTrade && newSelectedPositions.length > 0) {
      const closeOrderArgs = stableGetBulkClosablePositions.current(newSelectedPositions);
      if (closeOrderArgs.length > 0) {
        items.unshift('separator');
        items.unshift({
          name: `Close ${closeOrderArgs.length} position(s)`,
          action: () => openBulkClosePositionDialog(closeOrderArgs),
          icon: `<i class="ag-icon ${IconName.Close}"/>`,
        });
      }
    }

    if (authorizedToEditPosition && data != null && data.PositionSource === PositionUpdateSourceEnum.Internal) {
      items.push('separator');
      items.push({
        name: `Edit position`,
        action: () => handleUpdatePositions(data),
        icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
      });
    }

    if (showOpenTransactionHistoryMenuItem && enableAccountLedgerEventsPage && data != null) {
      const type = tabType === 'Sub Accounts' ? LedgerAccountTypeEnum.SubAccount : LedgerAccountTypeEnum.MarketAccount;
      const asset = data.Asset;
      const account = tabType === 'Sub Accounts' ? data.SubAccount : data.MarketAccount;
      // can add more to this ok statement in the future
      const ok = account != null;
      if (ok) {
        items.push('separator');
        items.push({
          name: 'Open transaction history',
          action: () => {
            navigateToAccountLedgerEvents({
              asset,
              account,
              type,
              source: MixpanelEventSource.PositionsBlotter,
            });
          },
          icon: `<i class="ag-icon ${IconName.ViewListDrilldown}"/>`,
        });
      }
    }

    return compact(items);
  });

  const dialogs = useMemo(
    () => (
      <>
        <BulkCloseDialog closablePositions={closablePositions} dialog={bulkClosePositionsDialog} />
        <UpdatePositionsDialog
          dialogProps={updatePositionsDialog}
          positionPrimer={editPositionPrimer}
          positionType={
            tabType === 'Sub Accounts' ? PositionTypeEnum.SubAccountPosition : PositionTypeEnum.MarketAccountPosition
          }
        />
        {jsonModal}
      </>
    ),
    [jsonModal, closablePositions, bulkClosePositionsDialog, updatePositionsDialog, editPositionPrimer, tabType]
  );

  // We want to constrain the security filtering to market account if we're in any other blotter than the sub account one,
  // because on those records we dont have any market accounts, so need to keep it loose.
  const constrainSecuritiesToMarket = tabType !== 'Sub Accounts';
  const closePositionColumn = useTradePositionColumn<Position>({
    assetField: 'Asset',
    action: 'Close',
    columnId: 'closePosition',
    colDefParams: pinClosePositionColumnColDefParams,
    getAssetType: getPositionAssetType,
    marketAccountField: 'MarketAccount',
    subAccountField: 'SubAccount',
    constrainSecuritiesToMarket,
  });

  const showClosePositionColumn = authorizedToTrade;

  const columns = useMemo(
    () =>
      compact([
        showClosePositionColumn ? closePositionColumn : undefined,
        {
          type: 'hamburgerMenu',
          id: 'rowMenu',
          params: {
            renderItems: params => <PositionMenu {...params} onShowJson={handleClickJson} />,
          },
        },
      ]) satisfies ColumnDef<Position>[],
    [handleClickJson, closePositionColumn, showClosePositionColumn]
  );

  return {
    columns,
    getContextMenuItems,
    dialogs,
    onClickJson: handleClickJson,
    openUpdatePositionDialog: updatePositionsDialog.open,
    openBulkClosePositionDialog,
  };
}

function getPositionAssetType(position: Position) {
  return position.AssetType;
}
