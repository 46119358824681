import { invariant } from '@epic-web/invariant';
import {
  applySortsToColumns,
  getTypedKeys,
  isGridApiReady,
  type BlotterTableSort,
  type UseBlotterTableProps,
} from '@talos/kyoko';
import type { ColDef, ColGroupDef, GridApi, GridOptions } from 'ag-grid-community';
import { get, isEqual, pick } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { PortfolioRiskDataItem } from '../../types/PortfolioRiskDataItem';
import {
  NON_PIVOT_DISPLAY_COLUMNS,
  type MoneynessServerTypes,
  type RiskAggMode,
  type RiskPivotType,
  type TenorServerTypes,
} from '../../types/types';
import { splitPivotField } from './usePortfolioRiskColumns';

/** Prefixes for non-pivot fields we want to hide/show on */
const nonPivotColumnPrefixes = getTypedKeys(NON_PIVOT_DISPLAY_COLUMNS);

/** Using the customColumnUpdate mechanism of {@link useBlotterTable}, handle the updates of the tenor
 * columns and moneyness columns based on the riskPivotType.
 */
export function usePivotColumnShowHide<R>({
  riskPivotType,
  riskAggMode,
  /** Persisted sort to apply to column defs */
  sort,
}: {
  riskPivotType: RiskPivotType;
  riskAggMode: RiskAggMode;
  sort?: BlotterTableSort<R>;
}): NonNullable<UseBlotterTableProps<PortfolioRiskDataItem>['customColumnUpdate']> {
  type Callback = NonNullable<UseBlotterTableProps<PortfolioRiskDataItem>['customColumnUpdate']>;
  const tenorColumnsRef = useRef(new Set<TenorServerTypes>());
  const moneynessColumnsRef = useRef(new Set<MoneynessServerTypes>());
  const [columnDefSetting, setColumnDefSetting] = useState<{
    visiblePivotFields: string[];
    autoGroupColumnDef: GridOptions['autoGroupColumnDef'];
    colDefs: NonNullable<GridOptions['columnDefs']>;
  }>({
    colDefs: [],
    autoGroupColumnDef: undefined,
    visiblePivotFields: [],
  });
  const [api, setApi] = useState<GridApi | null>(null);

  // AgGrid flickers the grouping column and the grouping headers
  // on every call to setColumnDefs so this reduces it
  type AutoGroupSortInfo = Pick<NonNullable<GridOptions['autoGroupColumnDef']>, 'sort' | 'sortIndex'>;
  const lastAutoGroupSortInfoRef = useRef<AutoGroupSortInfo>();
  const lastVisiblePivotFieldsRef = useRef<(typeof columnDefSetting)['visiblePivotFields'] | undefined>(undefined);
  const lastRiskPivotType = useRef<RiskPivotType>(riskPivotType);
  const lastRiskAggMode = useRef<RiskAggMode>(riskAggMode);
  useEffect(() => {
    if (isGridApiReady(api)) {
      const autoGroupSortInfo: AutoGroupSortInfo = pick(columnDefSetting.autoGroupColumnDef, ['sort', 'sortIndex']);
      if (columnDefSetting.autoGroupColumnDef && !isEqual(autoGroupSortInfo, lastAutoGroupSortInfoRef.current)) {
        lastAutoGroupSortInfoRef.current = autoGroupSortInfo;
        api.setGridOption('autoGroupColumnDef', columnDefSetting.autoGroupColumnDef);
      }

      // To avoid unwanted flickering only update the columnDefs if the visible pivot fields have changed from the
      // hide/show analysis process based on the latest row data and settings
      if (
        !isEqual(columnDefSetting.visiblePivotFields, lastVisiblePivotFieldsRef.current) ||
        !isEqual(riskPivotType, lastRiskPivotType.current) ||
        !isEqual(riskAggMode, lastRiskAggMode.current)
      ) {
        lastRiskPivotType.current = riskPivotType;
        lastRiskAggMode.current = riskAggMode;
        lastVisiblePivotFieldsRef.current = columnDefSetting.visiblePivotFields;

        // from the collected visiblePivotFields, hide/show the coldefs
        const colDefsToSet = columnDefSetting.colDefs.reduce((result, next) => {
          if ('children' in next) {
            // for the group columns, only show the children that are in the visiblePivotFields
            const children = next.children.filter(child => {
              if (!('children' in child) && child.field) {
                const [_a, _b, _greek, pivotType, _bucket, netGross] = splitPivotField(child.field);
                const show =
                  pivotType === riskPivotType &&
                  netGross === riskAggMode &&
                  columnDefSetting.visiblePivotFields.includes(child.field);
                child.hide = !show;
              }
              return true;
            });
            next.children = children;
          } else if (next.cellRenderer === 'iconButtonColumn') {
            next.hide = false;
          } else {
            const nextField = next.field;
            invariant(nextField, 'Non-Pivot columns must be based on a field defintion');
            if (nonPivotColumnPrefixes.some(item => nextField.startsWith(item))) {
              // for the Net/Gross Delta Exposure columns, show as simple columns (not groups) only those that match Net/Gross
              const [_a, _b, _greek, netGross] = nextField.split('.');
              invariant(netGross, `NonPivot column ${next.field} does not have a aggMode`);
              const show = netGross === riskAggMode;
              next.hide = !show;
            }
          }
          result.push(next);
          return result;
        }, [] as (ColDef | ColGroupDef)[]);
        api.setGridOption('columnDefs', colDefsToSet);
      }
    }
  }, [api, columnDefSetting, riskAggMode, riskPivotType]);

  // On every risk batch update, analyze the latest tenors and moneyness to ensure we have the columns to show
  // and update the columnDefs.
  //
  // Improvements: Refactor this check to be based on grid transaction completions (and potentially row filter changes)
  const customColumnUpdate = useCallback<Callback>(
    (arg): (() => void) => {
      const { api, dataObservable, columnDefs, autoGroupColumnDef } = arg;
      const sub = dataObservable.subscribe(subaccountRiskBatch => {
        const isInitial = subaccountRiskBatch.initial;
        const data = subaccountRiskBatch.data ?? [];
        if (isGridApiReady(api) && data.length > 0) {
          setApi(api);

          if (isInitial) {
            tenorColumnsRef.current.clear();
            moneynessColumnsRef.current.clear();
          }
          const visibleFields = new Set<string>();

          data.forEach(dataItem => {
            const item = dataItem.gridData;
            if (item) {
              item.TenorBucket && tenorColumnsRef.current.add(item.TenorBucket);
              item.MoneynessBucket && moneynessColumnsRef.current.add(item.MoneynessBucket);
            }
          });

          // the only pivot columns that should be shown are the group children
          const pivotColumn = columnDefs
            .filter(colDef => 'children' in colDef)
            .flatMap(colDef => {
              return colDef.children.filter(child => {
                const isGroupDef = 'children' in child;
                return !isGroupDef;
              }) as ColDef[];
            });

          // generate the new visibleFields set based on the latest data, as we only want to
          // show the pivot columns that have non-empty values
          pivotColumn.forEach(child => {
            if (child.field) {
              const field = child.field;
              const isValid = data.some(item => get(item, field));
              isValid && visibleFields.add(field);
            }
          });

          // only show the pivot columns that have non-empty values
          const resolvedVisibleFields = [...visibleFields].filter(field => {
            return data.some(item => get(item, field) != null);
          });

          // apply sorts to teh col defs - this is the only process updating the
          // column defs we do in this area
          const { workingAutoGroupColumnDef, workingColumnDefs } = applySortsToColumns({
            columnDefs,
            api,
            autoGroupColumnDef,
            initialSorts: sort,
          });
          setColumnDefSetting({
            colDefs: workingColumnDefs,
            autoGroupColumnDef: workingAutoGroupColumnDef,
            visiblePivotFields: resolvedVisibleFields.sort(),
          });
        }
      });
      return () => {
        sub.unsubscribe();
      };
    },
    [sort]
  );
  return customColumnUpdate;
}
