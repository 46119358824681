import { Button, ButtonGroup, IconButton } from '../Button';
import { Flex } from '../Core';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import { Text } from '../Text';
import type { ITourStepProps } from './types';

export function TourStep({
  title,
  description,
  showClose = false,
  showNavigation = true,
  onDismiss,
  onDismissAll,
  onNext,
  onPrev,
}: ITourStepProps) {
  const showActionButtons = onNext || onPrev || onDismiss || onDismissAll;
  return (
    <Flex flexDirection="column" maxWidth="260px" gap="spacingComfortable">
      <Flex alignItems="center" justifyContent="space-between">
        <Text color="colorTextImportant" fontWeight="fontWeightBold">
          {title}
        </Text>
        {showClose && (
          <IconButton icon={IconName.Close} size={FormControlSizes.Tiny} onClick={onDismiss} ghost={true} />
        )}
      </Flex>
      {description && (
        <Text color="colorTextImportant" fontSize="fontSizeSmall">
          {description}
        </Text>
      )}
      {showActionButtons && (
        <Flex justifyContent="space-between">
          <Flex gap="spacingSmall">
            {onDismiss != null && (
              <Button size={FormControlSizes.Tiny} onClick={onDismiss}>
                Dismiss
              </Button>
            )}
            {onDismissAll != null && (
              <Button size={FormControlSizes.Tiny} ghost={true} onClick={onDismissAll}>
                Stop showing tips
              </Button>
            )}
          </Flex>
          {showNavigation && (
            <ButtonGroup size={FormControlSizes.Tiny}>
              <IconButton
                icon={IconName.ChevronLeft}
                size={FormControlSizes.Tiny}
                onClick={onPrev}
                disabled={onPrev == null}
              />
              <IconButton
                icon={IconName.ChevronRight}
                size={FormControlSizes.Tiny}
                onClick={onNext}
                disabled={onNext == null}
              />
            </ButtonGroup>
          )}
        </Flex>
      )}
    </Flex>
  );
}
