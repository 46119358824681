import type { ITour } from '@talos/kyoko';

export enum TourName {
  v249DDHOrderForm = 'v2.49.0 DDH Order Form',
  v249DDHOrderBlotter = 'v2.49.0 DDH Order Blotter', // upon removal remove from AgGridSecurity.tsx
  v249DDHOrderBlotterFilledColumn = 'v2.49.0 DDH Order Blotter Filled Column', // upon removal remove from AgGridSecurity.tsx
  v249DDHOrderBlotterMenu = 'v2.49.0 DDH Order Menu',
  v249DDHOrderDetailsViewParentOrder = 'v2.49.0 DDH Order Details View Parent Order',
  v249DDHOrderDetailsViewHedgeOrder = 'v2.49.0 DDH Order Details View Hedge Order',
  v249FlexibleLayoutBasics = 'v2.49.1 Flexible layout basics',
}

export const tours: Record<string, ITour> = {
  [TourName.v249FlexibleLayoutBasics]: {
    steps: [
      {
        title: 'Flexible Layout',
        description: 'Customise your layout by dragging a tab or groups of tabs to a new location.',
        placement: 'right-start',
        anchor: '.dv-view:nth-child(2) .dv-tabs-container',
      },
      {
        title: 'Flexible Layout',
        description: 'Switch between layouts and reset your layout via the Trading Layout menu.',
        placement: 'bottom-end',
        anchor: '[data-testid="layout-menu-button"]',
      },
    ],
  },
  [TourName.v249DDHOrderForm]: {
    steps: [
      {
        title: 'Dynamic Delta Hedging',
        description:
          'Dynamic Delta Hedging will calculate the corresponding delta of each fill and auto-hedge using the selected perp/future and strategy.',
        placement: 'left-start',
        anchor: '[data-testid="order-form-ddh-section"]',
      },
    ],
  },
  [TourName.v249DDHOrderBlotter]: {
    steps: [
      {
        title: 'Dynamic Delta Hedging',
        description: 'This Icon indicates that Dynamic Delta Hedging is enabled on an order.',
        placement: 'right-start',
        anchor: '[data-testid="ddh-order-icon"]',
      },
    ],
  },
  [TourName.v249DDHOrderBlotterFilledColumn]: {
    steps: [
      {
        title: 'Dynamic Delta Hedging',
        description:
          'The Filled % cell for an order with Dynamic Delta Hedging enabled will display a DDH Summary in the tooltip.',
        placement: 'right-start',
        anchor: '.ddh-order-meter',
        offset: [-4, 8],
      },
    ],
  },
  [TourName.v249DDHOrderBlotterMenu]: {
    steps: [
      {
        title: 'Dynamic Delta Hedging',
        description:
          'The Hedge orders generated for an order with Dynamic Delta Hedging enabled can be shown or hidden via a toggle in the Order Blotter menu.',
        placement: 'auto-start',
        anchor: '[data-testid="order-options-menu"]',
      },
    ],
  },
  [TourName.v249DDHOrderDetailsViewParentOrder]: {
    steps: [
      {
        title: 'Dynamic Delta Hedging',
        description: 'View the Parent Order for this Hedge Order.',
        placement: 'left-start',
        anchor: '[data-testid="view-ddh-parent-order"]',
        offset: [-4, 8],
      },
    ],
  },
  [TourName.v249DDHOrderDetailsViewHedgeOrder]: {
    steps: [
      {
        title: 'Dynamic Delta Hedging',
        description: 'View all Hedge Orders generated as part of Dynamic Delta Hedging for the order.',
        placement: 'left-start',
        anchor: '[data-testid="view-ddh-orders"]',
        offset: [-4, 8],
      },
    ],
  },
};
