import type { CareOrder } from '@talos/kyoko';
import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Divider,
  FormControlSizes,
  IconButton,
  IconName,
  MixpanelEvent,
  baseTreeGroupColumnDef,
  createCSVFileName,
  getTreeRowBlotterGroupColDef,
  stringColumnComparatorMultiColumn,
  useBlotterTable,
  useBlotterTableExtrasMenu,
  useDateRangeFilter,
  useDisclosure,
  useDynamicCallback,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  type BlotterTableFilter,
  type Column,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import type { ColDef, GridOptions } from 'ag-grid-community';
import { useDetailsDrawer } from 'components/DetailsDrawer/useDetailsDrawer';
import { newCareOrder } from 'components/OMS/CareOrderFormView/CareOrderSlice';
import { OMSView } from 'components/OMS/OMSView';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo } from 'react';
import styled from 'styled-components';
import { openView } from '../../../components/OMS/OMSSlice';
import { CareOrderImportDialog } from './CareOrderImport/CareOrderImportDialog';
import { isQuoteRow, type CareOrderBlotterEntity } from './types';
import { useCareOrderBlotterDataObs } from './useCareOrderBlotterDataObs';
import { useCareOrderColumns } from './useCareOrderColumns';
import { useCareOrderFilter } from './useCareOrderFilter';
import { useCareOrderMenu } from './useCareOrderMenu';

const careOrderBlotterEntityComparator = stringColumnComparatorMultiColumn<CareOrderBlotterEntity>(['Symbol']);

function getDataPath(row: CareOrderBlotterEntity): string[] {
  return row.dataPath;
}

export interface CareOrderBlotterParams {
  blotterID: string;
  initialIsOpen?: boolean;
  portalId?: string;
  defaultFilter?: BlotterTableFilter;
  defaultColumns?: (keyof CareOrder | Partial<Column>)[];
  defaultRowGroupsOpened?: RowGroupsOpenedState;
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  && .ag-pinned-left-cols-container,
  && .ag-pinned-left-header {
    border-right: 1px solid var(--colors-gray-050);
  }
`;

export function CareOrderBlotter({
  blotterID,
  portalId,
  defaultColumns,
  defaultFilter,
  defaultRowGroupsOpened,
  initialIsOpen,
}: CareOrderBlotterParams) {
  const dispatch = useAppStateDispatch();
  const mixpanel = useMixpanel();

  const defaultCareOrderColumns = useCareOrderColumns({ defaultColumns });

  const persistedBlotterTable = usePersistedBlotterTable<CareOrderBlotterEntity>(blotterID, {
    columns: defaultCareOrderColumns,
    filter: defaultFilter,
    sort: '+ag-Grid-AutoColumn',
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const careOrderMenu = useCareOrderMenu();

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, careOrderMenu.column],
    [careOrderMenu.column, persistedBlotterTable.columns]
  );

  const handleColumnsChanged = useDynamicCallback((columns: Column[]) => {
    persistedBlotterTable.onColumnsChanged(columns);
  });

  const { openDetailsDrawer } = useDetailsDrawer();
  const handleDoubleClickRow = useDynamicCallback((data: CareOrderBlotterEntity) => {
    if (isQuoteRow(data)) {
      openDetailsDrawer({ entity: data });
    }
  });

  const autoGroupColumnDef: ColDef = useMemo(
    () =>
      ({
        ...baseTreeGroupColumnDef,
        headerName: 'ID',
        pinned: 'left',
        lockVisible: true,
        lockPinned: true,
        lockPosition: true,
        comparator: (valueA, valueB, nodeA, nodeB) => careOrderBlotterEntityComparator(nodeA.data, nodeB.data),
        width: 100,
        ...getTreeRowBlotterGroupColDef(),
      } satisfies GridOptions['autoGroupColumnDef']),
    []
  );

  const careOrderFilter = useCareOrderFilter({
    initialFilter: persistedBlotterTable.initialFilter,
    saveFilter: persistedBlotterTable.onFilterChanged,
  });
  const dateRangeFilter = useDateRangeFilter(careOrderFilter.filter, careOrderFilter.changeFilter);
  const dataObservable = useCareOrderBlotterDataObs();

  const blotterTable = useBlotterTable<CareOrderBlotterEntity>({
    dataObservable,
    rowID: 'rowID',
    selection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
    sort: persistedBlotterTable.initialSort,
    onColumnsChanged: handleColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    clientLocalFilter: careOrderFilter.clientSideFilter,
    columns: columnsWithMenu,
    getContextMenuItems: careOrderMenu.getContextMenuItems,
    suppressAggFuncInHeader: true,
    ...persistedRowGroupsOpened.blotterTableProps,
    ...({
      showOpenedGroup: true,
      autoGroupColumnDef,
      treeData: true,
      getDataPath,
    } satisfies GridOptions),
    onDoubleClickRow: handleDoubleClickRow,
  });

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      skipRowGroups: false,
      fileName: createCSVFileName({
        name: 'Care Orders',
        tabLabel: 'Care Orders',
      }),
    });
    extrasMenuPopover.close();
  });

  const handleClickNewCareOrder = useDynamicCallback(() => {
    dispatch(newCareOrder());
    dispatch(openView(OMSView.CareOrderForm));
  });

  const importDialog = useDisclosure();
  const handleClickImport = useDynamicCallback(() => {
    importDialog.open();
  });

  return (
    <Wrapper>
      <BlotterTableFilters
        portalId={portalId}
        {...blotterTable.blotterTableFiltersProps}
        {...dateRangeFilter}
        suffix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
            <Divider orientation="vertical" mx="spacingSmall" />
            <Button size={FormControlSizes.Small} startIcon={IconName.DocumentUpload} onClick={handleClickImport}>
              Import
            </Button>
            <Button
              size={FormControlSizes.Small}
              variant={ButtonVariants.Primary}
              startIcon={IconName.Plus}
              onClick={handleClickNewCareOrder}
            >
              New Care Order
            </Button>
            <BlotterTableExtrasMenu {...extrasMenuPopover}>
              <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
                Export
              </Button>
            </BlotterTableExtrasMenu>
          </>
        }
      />
      <BlotterTable {...blotterTable} />
      {careOrderMenu.dialogs}
      <CareOrderImportDialog {...importDialog} />
    </Wrapper>
  );
}
