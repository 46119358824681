import {
  Dialog,
  FormGroup,
  InlineFormattedNumber,
  NotificationVariants,
  NumericField,
  Patch,
  PriceInput,
  QuoteStatusEnum,
  SearchSelect,
  Table,
  TableSize,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Unit,
  VStack,
  abbreviateId,
  format,
  formattedDate,
  getScaleFromIncrement,
  percentToBps,
  toBig,
  useDisclosure,
  useDynamicCallback,
  useEndpointsContext,
  useGlobalToasts,
  useObservableValue,
  useSecurity,
  useStaticSubscription,
  validateMidPriceDiff,
  wsScanToMap,
  type CustomerQuote,
  type DialogProps,
  type FixingIndex,
} from '@talos/kyoko';
import { upperFirst } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useInterval } from 'react-use';
import { map } from 'rxjs';
import { useFeatureFlag, useMarketDataSnapshot } from '../../hooks';
import { useCustomersByName } from '../../hooks/useCustomer';
import { useFixingIndices } from '../../providers/FixingIndexProvider';
import { ORDER_SIDES } from '../../tokens/order';
import { MinTd, OrderSide } from '../OMS/OMSCard/styles';

export interface IFixQuoteDialogProps extends Omit<DialogProps, 'title' | 'confirmLabel' | 'onConfirm'> {
  selectedCustomerQuotes: CustomerQuote[];
}

function getFixingIndexLabel(fixingIndex: FixingIndex): string {
  return fixingIndex.DisplayName;
}

function getFixingIndexDescription(fixingIndex: FixingIndex): string {
  return fixingIndex.Description;
}

export function FixQuotePriceDialog({ selectedCustomerQuotes, ...props }: IFixQuoteDialogProps) {
  const { fixingIndicesByName, fixingIndicesBySymbol, fixingIndicesList } = useFixingIndices();
  const [fixingIndexEnabled, setFixingIndexEnabled] = useState<boolean>(false);
  const [fixingIndex, setFixingIndex] = useState<FixingIndex | undefined>(undefined);
  const [priceField, setPriceField] = useState<NumericField>(
    new NumericField({
      name: 'Fixing price',
      value: '',
      isTouched: false,
      isDisabled: false,
      isRequired: true,
      errors: [],
      isVisible: true,
    })
  );
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { orgApiEndpoint } = useEndpointsContext();
  const { add: addToast } = useGlobalToasts();
  const midPriceDialog = useDisclosure();

  useEffect(() => {
    if (selectedCustomerQuotes.length) {
      const fixingIndex = fixingIndicesByName.get(selectedCustomerQuotes[0].FixingDetails?.Index ?? '');
      setFixingIndex(fixingIndex);
      setFixingIndexEnabled(false);
      return;
    }

    setFixingIndex(undefined);
    setFixingIndexEnabled(true);
  }, [props.isOpen, selectedCustomerQuotes, fixingIndicesByName]);

  const handleChangePrice = (value: string | undefined) => {
    setPriceField(priceField => priceField.updateValue(value).setTouched(true));
  };

  const handleConfirm = useDynamicCallback(() => {
    if (orgApiEndpoint == null) {
      return;
    }

    if (!priceField.hasValue) {
      return;
    }

    if (midPx != null && !validateMidPriceDiff(midPx, priceField.value)) {
      midPriceDialog.open();
      return;
    }

    applyFixing();
  });

  const applyFixing = useDynamicCallback(() => {
    if (orgApiEndpoint == null) {
      return;
    }
    setConfirmLoading(true);
    Promise.all(
      filteredCustomerQuotes.map(quote =>
        Patch(orgApiEndpoint, `/customer/quotes/${quote.RFQID}/fix-price`, {
          Price: priceField.value,
        })
      )
    )
      .then(() => {
        props.close();
      })
      .catch(() => {
        addToast({
          text: 'Failed to apply fixing price on some quotes. Please try again.',
          variant: NotificationVariants.Negative,
          timeout: 5000,
        });
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  });

  // TODO fhqvst Placeholder until we have proper fixing times
  const [currentTime, setCurrentTime] = useState(formattedDate(Date.now(), '{dd}{Month}{yyyy}').toUpperCase());
  useInterval(() => {
    setCurrentTime(formattedDate(Date.now(), '{dd}{Month}{yyyy}').toUpperCase());
  }, 1000);

  // TODO fhqvst very inconvenient way of figuring out which symbol to use
  // as we currently do not have a proper way of mapping fixing indices to symbols
  const symbol = useMemo(() => {
    if (fixingIndex == null) {
      return;
    }
    for (const [key, fixingIndices] of fixingIndicesBySymbol.entries()) {
      if (fixingIndices.some(index => index.Name === fixingIndex.Name)) {
        return key;
      }
    }
  }, [fixingIndex, fixingIndicesBySymbol]);

  const security = useSecurity(symbol);

  useEffect(() => {
    setPriceField(priceField => {
      if (security?.Symbol == null) {
        return priceField.updateValue('').setTouched(false);
      }
      return priceField
        .updateValue('')
        .updateScale(getScaleFromIncrement(security.MinPriceIncrement))
        .updateUnit(Unit.Decimal)
        .setTouched(false);
    });
    // Specifically only react to changes w.r.t. symbol name or price increment
  }, [security?.MinPriceIncrement, security?.Symbol]);

  // In the future, this will be some kind of reference rate
  // instead of being sourced from an arbitrary set of markets
  const { fixingPriceDialogMarkets } = useFeatureFlag();
  const marketDataSnapshotMarkets = useMemo(() => fixingPriceDialogMarkets.split(','), [fixingPriceDialogMarkets]);
  const { marketDataSnapshots } = useMarketDataSnapshot({
    depth: 1,
    markets: marketDataSnapshotMarkets,
    throttle: '10s',
    currency: security?.PositionCurrency,
    symbol: security?.Symbol,
    tag: 'fix-quote-price',
    priceIncrement: security?.DefaultPriceIncrement,
    quantity: '1',
  });

  const [midPx, setMidPx] = useState<string | undefined>(undefined);
  useEffect(() => {
    const subscription = marketDataSnapshots.subscribe(mds => {
      // If either price is missing, try to use the other one
      const topOfBookBid = mds.Bids?.[0]?.Price;
      const topOfBookOffer = mds.Offers?.[0]?.Price;

      if (topOfBookBid != null && topOfBookOffer != null) {
        return setMidPx(toBig(topOfBookBid)?.plus(topOfBookOffer).div(2).toFixed(2) ?? undefined);
      }

      return setMidPx((toBig(topOfBookBid) ?? toBig(topOfBookOffer))?.toFixed(2) ?? undefined);
    });
    return () => {
      subscription.unsubscribe();
      setMidPx(undefined);
    };
  }, [marketDataSnapshots]);

  useEffect(() => {
    if (midPx != null) {
      setPriceField(priceField => (priceField.isTouched ? priceField : priceField.updateValue(midPx)));
    }
  }, [midPx]);

  const handleChangeFixingIndex = useDynamicCallback((selection?: FixingIndex) => {
    setPriceField(priceField => priceField.updateValue('').setTouched(false));
    setFixingIndex(selection);
  });

  const { data: customerQuotesObs } = useStaticSubscription<CustomerQuote>({
    name: 'CustomerQuote',
    tag: 'FixQuotePriceDialog',
    QuoteStatus: [QuoteStatusEnum.PendingFix],
    // TODO fhqvst add FixingDetails.Index filter here when supported
  });

  const customerQuotes = useObservableValue(
    () =>
      customerQuotesObs.pipe(
        wsScanToMap({
          getUniqueKey: item => item.RFQID,
          newMapEachUpdate: true,
        }),
        map(map => Array.from(map.values()))
      ),
    [customerQuotesObs]
  );
  const filteredCustomerQuotes = useMemo(() => {
    if (fixingIndex == null) {
      return [];
    }
    if (selectedCustomerQuotes.length === 0) {
      return (
        customerQuotes?.filter(
          quote => quote.QuoteStatus === QuoteStatusEnum.PendingFix && quote.FixingDetails?.Index === fixingIndex.Name
        ) ?? []
      );
    }

    const selectedRFQIDs = selectedCustomerQuotes.map(quote => quote.RFQID);
    return customerQuotes?.filter(quote => selectedRFQIDs.includes(quote.RFQID)) ?? [];
  }, [customerQuotes, selectedCustomerQuotes, fixingIndex]);

  return (
    <>
      <Dialog
        {...props}
        width={400}
        title="Apply Fixing"
        confirmLabel="Apply Fixing"
        confirmDisabled={priceField.hasInvalidValue || filteredCustomerQuotes.length === 0}
        onConfirm={handleConfirm}
        confirmLoading={confirmLoading}
        closeOnConfirm={false}
        data-testid="apply-fixing-dialog"
      >
        <FormGroup label="Fixing Index">
          <SearchSelect
            w="100%"
            selection={fixingIndex}
            options={fixingIndicesList}
            getLabel={getFixingIndexLabel}
            getDescription={getFixingIndexDescription}
            disabled={!fixingIndexEnabled}
            onChange={handleChangeFixingIndex}
            data-testid="apply-fixing-dialog-index-dropdown"
          />
        </FormGroup>
        <FixingCardTable customerQuotes={filteredCustomerQuotes} />
        {/* TODO fhqvst Placeholder until we have proper fixing times */}
        <FormGroup
          label="Index price"
          labelSuffix={`${currentTime} - 4PM NY Time`}
          mt="spacingMedium"
          error={priceField.errorString}
          textAlign="left"
        >
          <PriceInput
            autoFocus={true}
            value={priceField.displayValue ?? ''}
            onChange={handleChangePrice}
            currency={security?.QuoteCurrency}
            data-testid="apply-fixing-dialog-price-input"
            step={security?.DefaultPriceIncrement}
          />
        </FormGroup>
      </Dialog>
      <Dialog
        {...midPriceDialog}
        usePortal={false}
        onConfirm={applyFixing}
        cancelLabel="No"
        confirmLabel="Yes"
        onCancel={() => midPriceDialog.close()}
        width={360}
      >
        <VStack gap="spacingMedium">
          <div>
            Price{' '}
            {format(priceField.value, {
              pretty: true,
            })}{' '}
            USD is more than 5% away from current reference price{' '}
            {format(midPx, {
              pretty: true,
            })}{' '}
            USD
          </div>
          <div>Are you sure you want to continue?</div>
        </VStack>
      </Dialog>
    </>
  );
}

function FixingCardTable({ customerQuotes }: { customerQuotes: CustomerQuote[] }) {
  const customers = useCustomersByName();

  const symbol = customerQuotes[0]?.Symbol;
  const security = useSecurity(symbol);

  if (customers == null) {
    return null;
  }

  const { DefaultPriceIncrement, PriceDisplaySpec, DefaultSizeIncrement, BaseCurrency } = security ?? {};
  const fixingIndex = customerQuotes[0]?.FixingDetails?.Index;

  return (
    <Table data-testid="apply-fixing-dialog-table" size={TableSize.Small} fontSize="fontSizeSmall" w="100%">
      <Thead>
        <Tr>
          <Th>ID</Th>
          <Th>Customer</Th>
          <Th colSpan={4} align="right">
            {fixingIndex} +/-
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {customerQuotes.length === 0 ? (
          <Tr>
            <Td colSpan={6} align="center">
              <Text color="colorTextMuted">No quotes selected.</Text>
            </Td>
          </Tr>
        ) : (
          customerQuotes.map(quote => (
            <Tr key={quote.RFQID}>
              <Td width="0%" color="colorTextDefault">
                #{abbreviateId(quote.RFQID)}
              </Td>
              <Td>{customers.get(quote.Counterparty)?.DisplayName ?? quote.Counterparty}</Td>
              <Td>
                <OrderSide isBuy={quote.TradedSide === ORDER_SIDES.BUY} isSell={quote.TradedSide === ORDER_SIDES.SELL}>
                  {upperFirst(quote.TradedSide ?? '-')}
                </OrderSide>
              </Td>
              <MinTd align="right">
                <InlineFormattedNumber
                  number={quote.OrderQty}
                  increment={DefaultSizeIncrement}
                  specification={PriceDisplaySpec}
                  currency={BaseCurrency}
                />
              </MinTd>
              <MinTd>@</MinTd>

              <MinTd>
                <InlineFormattedNumber
                  number={percentToBps(quote.PricingReference)}
                  increment={DefaultPriceIncrement}
                  specification={PriceDisplaySpec}
                  showSign={true}
                  currency="BPS"
                />
              </MinTd>
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
}
