import {
  BlotterDensity,
  BlotterTable,
  Box,
  HedgeControlStatusEnum,
  MixpanelEventSource,
  MixpanelSourceProvider,
  WARNING_ROW_CLASSNAME,
  useBlotterTable,
  type BlotterTableClientLocalFilter,
} from '@talos/kyoko';

import type { GridOptions } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useHedgePositionStatusMenu } from '../../containers/Blotters/HedgePositionStatus/columnMenu';
import { useHedgePositionStatusColumns } from '../../containers/Blotters/HedgePositionStatus/columns';
import { useHedgePositionStatus, type IHedgePositionStatusRow } from '../../providers/HedgePositionStatusProvider';
import { usePositionAutoHedgingRulesDrawer } from '../AutoHedgingRulesDrawer/AutoHedgingRulesDrawer';

import { DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS } from '@talos/kyoko';
/**
 * Small table for displaying the status of the autohedging positions.
 */
export function SmallHedgePositionStatusBlotterTable({
  status,
  search,
}: {
  status: HedgeControlStatusEnum | undefined;
  search: string;
}) {
  const clientRowFilter: BlotterTableClientLocalFilter<IHedgePositionStatusRow> = useCallback(
    row => {
      if (!status) {
        return true;
      }
      if (row.data?.HedgeControlStatus === status) {
        return true;
      }
      return false;
    },
    [status]
  );

  const { hedgePositionStatusDeltasObs } = useHedgePositionStatus();
  const { openPositionAutoHedgingRulesDrawer } = usePositionAutoHedgingRulesDrawer();

  const columns = useHedgePositionStatusColumns({ size: 'mini' });
  const { getContextMenuItems, modals } = useHedgePositionStatusMenu();

  const blotterTable = useBlotterTable({
    dataObservable: hedgePositionStatusDeltasObs,
    columns: columns,
    rowID: 'HedgeRuleID',
    clientLocalFilter: clientRowFilter,
    selection: DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
    quickSearchParams: {
      filterText: search,
    },
    getContextMenuItems,
    onDoubleClickRow: row => {
      openPositionAutoHedgingRulesDrawer({ hedgeRuleID: row.HedgeRuleID });
    },
  });

  const gridOptions = useMemo(() => {
    return {
      ...blotterTable.gridOptions,
      rowClassRules: {
        [WARNING_ROW_CLASSNAME]: params => params?.data?.HedgeControlStatus === HedgeControlStatusEnum.Error,
      },
      defaultColDef: { suppressHeaderMenuButton: true },
    } satisfies GridOptions<IHedgePositionStatusRow>;
  }, [blotterTable.gridOptions]);

  const theme = useTheme();
  return (
    <>
      <Box w="100%" h="200px">
        <MixpanelSourceProvider value={MixpanelEventSource.PositionAutoHedgingMiniBlotter}>
          <BlotterTable
            {...blotterTable}
            gridOptions={gridOptions}
            density={BlotterDensity.VeryComfortable}
            background={theme.colors.gray['040']}
          />
        </MixpanelSourceProvider>
      </Box>
      {modals}
    </>
  );
}
