import { ACTION, ROLE, type User } from '@talos/kyoko';
import { chain, isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useUser } from './useUser';

// Exlude `talos.admin` here since it's a special role that bypasses all permission checks
export const RULES: Record<ACTION, Exclude<ROLE, ROLE.TALOS_ADMIN>[]> = {
  [ACTION.VIEW_ORDER]: [ROLE.TALOS_VIEWER, ROLE.TALOS_SUPPORT, ROLE.TRADER, ROLE.ADMIN],
  [ACTION.SUBMIT_ORDER]: [ROLE.TRADER],
  [ACTION.PAUSE_ORDER]: [ROLE.TRADER, ROLE.ADMIN],
  [ACTION.RESUME_ORDER]: [ROLE.TRADER, ROLE.ADMIN, ROLE.TALOS_SUPPORT], // talos.support can resume orders, but not pause
  [ACTION.CANCEL_ORDER]: [ROLE.TRADER, ROLE.TALOS_CANCEL_ORDERS],
  [ACTION.FORCE_CANCEL_ORDER]: [ROLE.TALOS_SUPPORT],
  [ACTION.FORCE_FILL_ORDER]: [ROLE.TALOS_SUPPORT, ROLE.ADMIN],
  [ACTION.REQUEST_RFQ]: [ROLE.TRADER],
  [ACTION.ACCEPT_RFQ]: [ROLE.TRADER],
  [ACTION.VIEW_ORDER_FORM]: [ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER, ROLE.TRADER, ROLE.ADMIN],
  [ACTION.VIEW_RFQ_FORM]: [ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER, ROLE.TRADER, ROLE.ADMIN],
  [ACTION.VIEW_SALES_ORDER_FORM]: [ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER, ROLE.TRADER, ROLE.ADMIN],
  [ACTION.VIEW_SALES_RFQ_FORM]: [ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER, ROLE.TRADER, ROLE.ADMIN],
  [ACTION.EDIT_MARKET_SETTINGS]: [ROLE.ADMIN, ROLE.TALOS_SUPPORT, ROLE.TRADING_CONFIG],
  [ACTION.EDIT_AGGREGATION_SETTINGS]: [ROLE.ADMIN, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_SUB_ACCOUNTS]: [ROLE.ADMIN, ROLE.TRADING_CONFIG, ROLE.TALOS_SUPPORT],
  [ACTION.CLONE_SUB_ACCOUNTS]: [ROLE.ADMIN, ROLE.TRADING_CONFIG, ROLE.TRADER, ROLE.TALOS_SUPPORT],
  [ACTION.SUBMIT_TRANSFER]: [ROLE.TRANSFERS],
  [ACTION.EDIT_LIMITS]: [ROLE.ADMIN, ROLE.TRADING_CONFIG, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_MAX_ORDER_SIZE_LIMITS]: [ROLE.ADMIN, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_COUNTERPARTY_LIMITS]: [ROLE.ADMIN, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_BALANCE]: [ROLE.TRADER, ROLE.ADMIN],
  [ACTION.VIEW_DEALER]: [ROLE.TALOS_VIEWER, ROLE.DEALER_VIEWER, ROLE.DEALER, ROLE.TALOS_SUPPORT],
  [ACTION.DEALER_TRADING]: [ROLE.DEALER, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_DEFAULT_PRICING_RULE]: [ROLE.DEALER, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_EXECUTION_RULES_MODE]: [ROLE.ADMIN, ROLE.DEALER, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_DEALER_TRADING]: [ROLE.ADMIN, ROLE.TALOS_SUPPORT],
  [ACTION.VIEW_ORDER_ANALYTICS]: [ROLE.TALOS_VIEWER, ROLE.TALOS_SUPPORT, ROLE.DEALER],
  [ACTION.FORCE_WITHDRAWAL]: [ROLE.DEALER_ADMIN_OPS, ROLE.TALOS_SUPPORT],
  [ACTION.VIEW_PORTFOLIO]: [ROLE.TALOS_VIEWER, ROLE.TALOS_SUPPORT, ROLE.PORTFOLIO],
  [ACTION.EDIT_SECMASTER]: [ROLE.TRADER, ROLE.ADMIN, ROLE.TALOS_SUPPORT, ROLE.SECMASTER],
  [ACTION.EDIT_SECMASTER_ADVANCED]: [ROLE.TALOS_SUPPORT, ROLE.SECMASTER],
  [ACTION.APPROVE_PENDING_TRANSACTION]: [ROLE.TREASURY],
  [ACTION.APPROVE_PENDING_TRANSFER]: [ROLE.TREASURY],
  [ACTION.VIEW_TALOS_ADMIN_SETTINGS]: [ROLE.TALOS_VIEWER, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_FILTER_PERMISSIONS]: [ROLE.TALOS_SUPPORT, ROLE.ADMIN, ROLE.TRADING_CONFIG, ROLE.USER_ADMIN],
  [ACTION.VIEW_FILTER_PERMISSIONS_FEATURE_DISABLED]: [ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER],
  [ACTION.EDIT_CONFIGURATIONS]: [ROLE.TALOS_SUPPORT],
  [ACTION.BYPASS_FILTER_PERMISSIONS_RESTRICTIONS_SUBACCOUNTS]: [ROLE.TALOS_VIEWER, ROLE.TALOS_SUPPORT, ROLE.ADMIN],
  [ACTION.EDIT_ROLLUPS]: [ROLE.ADMIN, ROLE.TRADING_CONFIG],
  [ACTION.EDIT_MARKET_SESSION_LIMIT]: [ROLE.TALOS_SUPPORT, ROLE.ADMIN, ROLE.USER_ADMIN],
  [ACTION.VIEW_ANALYTICS]: [ROLE.TALOS_VIEWER, ROLE.TALOS_SUPPORT, ROLE.ANALYTICS],
  [ACTION.EDIT_ADDRESSES]: [ROLE.DEALER, ROLE.PORTFOLIO],
  [ACTION.TOGGLE_KILLSWITCH]: [ROLE.ADMIN],
  [ACTION.MANUAL_ORDER_FILL]: [ROLE.TALOS_SUPPORT, ROLE.ADMIN],
  [ACTION.MANUAL_RFQ_PRICING]: [ROLE.TALOS_SUPPORT, ROLE.TRADER, ROLE.ADMIN],
  [ACTION.EDIT_USERS]: [ROLE.USER_ADMIN, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_MARKET_CREDENTIALS]: [ROLE.USER_ADMIN, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_CUSTOMER_BALANCE]: [ROLE.TALOS_SUPPORT],
  [ACTION.USE_ORGANIZATION]: [ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER],
  [ACTION.MORPH_USER]: [ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_POSITION]: [ROLE.TALOS_SUPPORT, ROLE.ADMIN],
  [ACTION.EDIT_TRADE]: [ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_ORGANIZATIONS]: [ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_MARKET_ACCOUNTS]: [ROLE.ADMIN, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_COUNTERPARTIES]: [ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_MARKET_CONNECTIONS]: [ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_PROCESSES]: [ROLE.TALOS_SUPPORT],
  [ACTION.VIEW_CREDENTIALS]: [ROLE.ADMIN, ROLE.TALOS_SUPPORT, ROLE.CREDENTIALS_ADMIN, ROLE.TALOS_VIEWER],
  [ACTION.EDIT_CREDENTIALS]: [ROLE.ADMIN, ROLE.TALOS_SUPPORT, ROLE.CREDENTIALS_ADMIN],
  [ACTION.VIEW_FIX_CONNECTIONS]: [ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER],
  [ACTION.VIEW_TRADING_CONTROLS]: [
    ROLE.ADMIN,
    ROLE.TALOS_SUPPORT,
    ROLE.TALOS_VIEWER,
    ROLE.TRADING_CONFIG,
    ROLE.TRADING_CONTROLS_ADMIN,
  ],
  [ACTION.REQUEST_EQUITY_MARGIN_RATE_LIMIT]: [ROLE.ADMIN, ROLE.TRADING_CONFIG, ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER],
  [ACTION.VIEW_TRADING_CONTROLS_DIALOG]: [ROLE.ADMIN, ROLE.TALOS_SUPPORT, ROLE.TRADING_CONFIG],
  [ACTION.VIEW_ALL_MARKET_ACCOUNTS_IN_TRADING_CONTROLS]: [
    ROLE.ADMIN,
    ROLE.TALOS_SUPPORT,
    ROLE.TALOS_VIEWER,
    ROLE.TALOS_ONBOARDING_ADMIN,
  ],
  [ACTION.EDIT_PORTFOLIO_ENGINEERING]: [ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER, ROLE.PORTFOLIO_ENGINEERING],
  [ACTION.VIEW_CUSTOMER_CONFIGURATIONS]: [ROLE.DEALER_VIEWER, ROLE.DEALER], // + ui.ShowCustomerTiering config must also be true.
  [ACTION.EDIT_CUSTOMER_CONFIGURATIONS]: [ROLE.DEALER, ROLE.TALOS_SUPPORT],
  [ACTION.VIEW_CUSTOMER_PRICING_TIERS]: [ROLE.DEALER_VIEWER, ROLE.DEALER], // + ui.ShowCustomerTiering config must also be true.
  [ACTION.EDIT_CUSTOMER_PRICING_TIERS]: [ROLE.DEALER, ROLE.TALOS_SUPPORT],
  [ACTION.VIEW_CUSTOMER_SYMBOL_GROUPS]: [ROLE.DEALER_VIEWER, ROLE.DEALER], // + ui.ShowCustomerTiering config must also be true.
  [ACTION.EDIT_CUSTOMER_SYMBOL_GROUPS]: [ROLE.DEALER, ROLE.TALOS_SUPPORT],
  [ACTION.SUBMIT_CARE_ORDER]: [ROLE.TRADER, ROLE.ADMIN],
  [ACTION.VIEW_MONITORING_ORDER_CONTEXT]: [ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER],

  [ACTION.VIEW_PORTFOLIO_RECON]: [ROLE.PORTFOLIO, ROLE.ADMIN, ROLE.TALOS_SUPPORT],
  [ACTION.EDIT_PORTFOLIO_RECON]: [ROLE.TREASURY, ROLE.TALOS_SUPPORT],
  [ACTION.HEDGER_SYNCHRONIZATION]: [ROLE.TALOS_SUPPORT],
  [ACTION.VIEW_LEDGER_EVENTS]: [ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER],
  [ACTION.VIEW_AUTOHEDGING]: [ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER, ROLE.AUTOHEDGING_VIEW, ROLE.AUTOHEDGING_ADMIN],
  [ACTION.EDIT_AUTOHEDGING]: [ROLE.TALOS_SUPPORT, ROLE.AUTOHEDGING_ADMIN],
};

export const useRoleAuth = () => {
  const user = useUser();
  const isAuthorized = useCallback((action: ACTION) => isUserAuthorized(user, action, RULES), [user]);
  const isRole = useCallback((role: ROLE) => isUserRole(user, role), [user]);

  return { isAuthorized, isRole };
};

export const isUserRole = (user: User, role: ROLE): boolean => Boolean(user?.Roles?.includes(role));

export const isUserMonitor = (user: User) => user?.Name?.startsWith('Monitor-');

export const isUserAuthorized = (user: User, action: ACTION, rules: typeof RULES = RULES) => {
  if (user == null || user.Roles == null || isEmpty(user.Roles)) {
    return false;
  }

  // Bypass permission checks for talos.admin
  if (user.Roles.includes(ROLE.TALOS_ADMIN)) {
    return true;
  }

  return !isEmpty(chain(user.Roles).intersection(rules[action]).value());
};
