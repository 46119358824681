import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  CUSTOMER_TRANSACTION,
  DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
  FormControlSizes,
  IconName,
  InlineFormattedNumberContext,
  MixpanelEvent,
  MixpanelEventProperty,
  Toggle,
  createCSVFileName,
  filterByCellValueMenuItem,
  filterExistsAndExcludes,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useConstant,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  usePersistedBlotterTable,
  useWSFilterPipe,
  useWsBlotterTable,
  type Column,
  type CustomerTransaction,
  type WebsocketRequest,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams } from 'ag-grid-community';
import { pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { defaultCustomerTransactionColumns, defaultCustomerTransactionFilter } from './defaults';
import type { CustomerTransactionsFilter, CustomerTransactionsTabsProps } from './types';
import { useCustomerTransactionColumns } from './useCustomerTransactionColumns';
import { colIDToFilterBuilderKey, useCustomerTransactionsFilter } from './useCustomerTransactionsFilter';

function getCustomerTransactionKey(ct: CustomerTransaction) {
  return ct.TransactionID;
}

interface CustomerTransactionsBlotterProps {
  blotterID: string;
  tab: CustomerTransactionsTabsProps;
  onUpdateTab: (tab: CustomerTransactionsTabsProps) => void;
  initialFiltersOpen?: boolean;
  onCloneTab: (filter: CustomerTransactionsFilter, columns: Column[]) => void;
  onClickRow: (customerTransaction: CustomerTransaction) => void;
}

export const CustomerTransactionsBlotter = ({
  blotterID,
  tab,
  initialFiltersOpen,
  onCloneTab,
  onClickRow,
  onUpdateTab,
}: CustomerTransactionsBlotterProps) => {
  const mixpanel = useMixpanel();

  const defaultBlotterColumns = tab.defaultColumns ?? defaultCustomerTransactionColumns;
  const defaultFilter = tab.defaultFilter ?? defaultCustomerTransactionFilter;
  const showAllDecimals = tab.showAllDecimals ?? false;
  const setShowAllDecimals = useDynamicCallback((showAllDecimals: boolean) => {
    onUpdateTab({ ...tab, showAllDecimals });
  });

  const defaultColumns = useCustomerTransactionColumns(defaultBlotterColumns);
  const persistedBlotterTable = usePersistedBlotterTable<CustomerTransaction>(blotterID, {
    columns: defaultColumns,
    filter: defaultFilter,
    sort: '-Timestamp',
  });

  const filterResults = useCustomerTransactionsFilter({
    persistedBlotterTable,
  });

  const { filterBuilderProps } = filterResults;

  const handleDisplayedColumnsChanged = useDynamicCallback((columns: Column<any>[]) => {
    mixpanel.track(MixpanelEvent.ChangeBlotterColumns, {
      [MixpanelEventProperty.TabLabel]: 'Customer Transfers',
    });
    persistedBlotterTable.onColumnsChanged(columns);
  });

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialFiltersOpen },
    filterBuilderProps: filterResults.filterBuilderProps,
  });

  const rxjsFilterFunc = useCallback(
    (data: CustomerTransaction) => {
      if (filterExistsAndExcludes(filterResults.filter, 'Currencies', data, 'Currency')) {
        return false;
      }

      if (filterExistsAndExcludes(filterResults.filter, 'Counterparties', data, 'Counterparty')) {
        return false;
      }

      if (filterExistsAndExcludes(filterResults.filter, 'Statuses', data, 'Status')) {
        return false;
      }

      return true;
    },
    [filterResults.filter]
  );
  const filterPipe = useWSFilterPipe({ getUniqueKey: getCustomerTransactionKey, filterFunc: rxjsFilterFunc });

  const { handleClickJson, jsonModal } = useJsonModal();
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) => [
      ...filterByCellValueMenuItem({
        params,
        openClause: filterBuilderAccordion.openClause,
        filterableProperties: filterBuilderProps.properties,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      'separator',
      {
        action: () => handleClickJson(params.node?.data),
        name: 'Show JSON',
        icon: `<i class="ag-icon ${IconName.Braces}"/>`,
      },
      'separator',
      ...getDefaultContextMenuItems(params),
    ],
    [
      filterBuilderAccordion.openClause,
      filterBuilderProps.properties,
      mixpanel,
      handleClickJson,
      getDefaultContextMenuItems,
    ]
  );

  const request = useMemo(
    () =>
      ({
        name: CUSTOMER_TRANSACTION,
        tag: blotterID,
        sort_by: '-Timestamp',
      } satisfies WebsocketRequest),
    [blotterID]
  );

  const initialRequest = useConstant(request);

  const blotterTable = useWsBlotterTable({
    initialRequest,
    filter: onlyServerKeys(filterResults.filter),
    initialSort: persistedBlotterTable.initialSort,
    pipe: filterPipe,
    rowID: 'ClReqID' satisfies keyof CustomerTransaction,
    selection: DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
    columns: persistedBlotterTable.columns,
    handleClickJson,
    onSortChanged: persistedBlotterTable.onSortChanged,
    onColumnsChanged: handleDisplayedColumnsChanged,
    onDoubleClickRow: onClickRow,
    getContextMenuItems,
  });

  const handleCloneTab = useDynamicCallback(() => {
    onCloneTab?.(filterResults.filter, blotterTable.getColumns());
  });

  const handleClickExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Customer Transfers',
        tabLabel: tab.label,
      }),
    });
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTable.blotterTableFiltersProps}
        {...filterResults.blotterTableFilterProps}
        suffix={
          <>
            <BlotterTableExtrasMenu {...extrasMenuPopover}>
              <Toggle
                size={FormControlSizes.Small}
                checked={showAllDecimals}
                onChange={setShowAllDecimals}
                label="Show All Decimals"
                data-testid="show-all-decimals-toggle"
              />
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
              <Button
                startIcon={IconName.DocumentDownload}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleClickExport}
              >
                Export
              </Button>
            </BlotterTableExtrasMenu>
          </>
        }
      />
      <InlineFormattedNumberContext.Provider value={useMemo(() => ({ showAllDecimals }), [showAllDecimals])}>
        <BlotterTable {...blotterTable} />
      </InlineFormattedNumberContext.Provider>
      {jsonModal}
    </>
  );
};

function onlyServerKeys(filter: CustomerTransactionsFilter | undefined): CustomerTransactionsFilter {
  if (filter == null) {
    return {};
  }

  return pick(filter, ['StartDate', 'EndDate']);
}
