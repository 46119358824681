import {
  ACTION,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  BlotterDensity,
  BlotterTable,
  BlotterTableFilters,
  Box,
  Button,
  ButtonVariants,
  ConnectionStatusEnum,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Divider,
  Flex,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  OnboardingGuide,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  createCSVFileName,
  useAccordionFilterBuilder,
  useBlotterTable,
  useConnectionStatusContext,
  useDrawer,
  useDynamicCallback,
  useMarketsContext,
  useMixpanel,
  useObservable,
  usePersistedBlotterTable,
  usePortal,
  useSyncedRef,
  useUserContext,
  withAccordionGroup,
  type ConnectionStatus,
  type Market,
  type MarketConfig,
  type MarketCredential,
  type SubscriptionResponse,
} from '@talos/kyoko';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { combineLatest, of } from 'rxjs';
import { useRoleAuth } from '../../../hooks';
import { useFees } from '../../../providers';
import { TradingControlsDetailsDrawerWrapper } from '../TradingControls/TradingControlsDetailsDrawerWrapper';
import { useTradingControlsDetailsDrawerWrapper } from '../TradingControls/useTradingControlsDetailsDrawerWrapper';
import { AddCredentialDrawer } from './AddCredentialDrawer';
import { useCredentialsBlotterTableMenu } from './CredentialsBlotterTableMenu';
import { EditCredentialDrawer } from './EditCredentialDrawer';
import type { HydratedMarketCredential } from './types';
import { useColumns } from './useColumns';
import { useCredentialsFilter } from './useCredentialsFilter';

export const CredentialsBlotter = withAccordionGroup(function Credentials() {
  const mixpanel = useMixpanel();
  const { listMarketCredentials, listMarketConfigs, orgMetadata } = useUserContext();
  const { listMarketFees } = useFees();
  const [marketCredentials, setMarketCredentials] = useState<MarketCredential[]>([]);
  const [marketConfigs, setMarketConfigs] = useState<MarketConfig[]>([]);
  const [selectedCredential, setSelectedCredential] = useState<MarketCredential | undefined>();
  const [selectedMarket, setSelectedMarket] = useState<Market | undefined>(undefined);
  const [mktAccsWithFees, setMktAccsWithFees] = useState<Set<string>>();
  const [externalIP, setExternalIP] = useState('');
  const { updateMarketCredentials, connectionStatusByCredentialIDAndMarketName } = useConnectionStatusContext();
  const { marketsByName } = useMarketsContext();
  const { isAuthorized } = useRoleAuth();
  const canEditCredential = isAuthorized(ACTION.EDIT_CREDENTIALS);

  const marketsByNameRef = useSyncedRef(marketsByName);

  useEffect(() => {
    orgMetadata().then(metadata => setExternalIP(metadata['ExternalIP']));
  }, [orgMetadata]);

  useEffectOnce(() => {
    listMarketFees().then(fees => setMktAccsWithFees(new Set(fees.map(fee => fee.MarketAccount))));
    refreshData();
  });

  const refreshData = useDynamicCallback(() => {
    listMarketConfigs().then(configs => setMarketConfigs(configs));
    listMarketCredentials().then(credentials => {
      setMarketCredentials(credentials);
    });
    updateMarketCredentials();
  });

  const filteredMarketCredentials = useMemo(() => {
    return marketCredentials.filter(
      (credential: MarketCredential) =>
        marketsByNameRef.current.has(credential.Market) && marketConfigs.some(mc => mc.name === credential.Market)
    );
  }, [marketCredentials, marketConfigs, marketsByNameRef]);

  const addCredentialDrawer = useDrawer({
    position: 'relative',
    width: 720,
    placement: 'right',
  });
  const editCredentialDrawer = useDrawer({
    position: 'relative',
    width: 720,
    placement: 'right',
  });

  const { tradingDetailsDrawer, selectedMarketAccount, handleOpenTradingControls } =
    useTradingControlsDetailsDrawerWrapper();

  const handleAddCredential = useDynamicCallback(() => {
    setSelectedCredential(undefined);
    setSelectedMarket(undefined);
    tradingDetailsDrawer.close();
    editCredentialDrawer.close();
    addCredentialDrawer.open();
  });

  const handleEditCredential = useDynamicCallback((marketCredential: MarketCredential) => {
    setSelectedCredential(marketCredential);
    const market = marketsByName.get(marketCredential.Market);
    handleMarketSelected(market);
    tradingDetailsDrawer.close();
    addCredentialDrawer.close();
    editCredentialDrawer.open();
  });

  const handleOnSaved = useDynamicCallback(() => {
    setSelectedCredential(undefined);
    setSelectedMarket(undefined);
    addCredentialDrawer.close();
    editCredentialDrawer.close();
    refreshData();
  });

  const handleOpenTradingControlsDrawer = useCallback(
    (marketAccountName: string) => {
      mixpanel.track(MixpanelEvent.ViewTradingControl);
      addCredentialDrawer.close();
      editCredentialDrawer.close();
      handleOpenTradingControls(marketAccountName);
    },
    [addCredentialDrawer, editCredentialDrawer, handleOpenTradingControls, mixpanel]
  );

  const handleMarketSelected = useDynamicCallback((market: Market | undefined) => {
    setSelectedMarket(market);
  });

  const selectedMarketConfig = useMemo(
    () => marketConfigs.find(mktCfg => mktCfg.name === selectedMarket?.Name),
    [marketConfigs, selectedMarket?.Name]
  );

  const defaultColumns = useColumns({ canEditCredential, handleEdit: handleEditCredential });

  const persistedBlotterTable = usePersistedBlotterTable<HydratedMarketCredential>('credentials', {
    columns: defaultColumns,
    sort: '+Market',
  });

  const filterResults = useCredentialsFilter({
    persistedBlotterTable,
  });
  const { clientSideFilter: clientLocalFilter, filterBuilderProps } = filterResults;
  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: true },
    filterBuilderProps,
  });
  const blotterTableMenu = useCredentialsBlotterTableMenu({
    canEditCredential,
    onEditCredential: handleEditCredential,
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
    marketConfigs,
    onUpdate: refreshData,
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...blotterTableMenu.columns],
    [persistedBlotterTable.columns, blotterTableMenu.columns]
  );

  const dataObservable = useObservable<SubscriptionResponse<HydratedMarketCredential>>(
    () =>
      combineLatest(
        [of(filteredMarketCredentials), connectionStatusByCredentialIDAndMarketName],
        (marketCredentials: MarketCredential[], statuses: Map<number, Map<string, ConnectionStatus>>) => {
          const hydratedData: HydratedMarketCredential[] = marketCredentials.map(credential => ({
            ...credential,
            RowID: `${credential.CredentialID}-${credential.Market}`, // Blotter table does not work well with numbers as rowID
            Status:
              statuses?.get(credential.CredentialID)?.get(credential.Market)?.Status ?? ConnectionStatusEnum.Offline,
            MarketType: marketsByNameRef.current.get(credential.Market)?.Type,
          }));
          return { data: hydratedData, initial: true, type: 'MarketCredential', ts: '0' };
        }
      ),
    [filteredMarketCredentials, connectionStatusByCredentialIDAndMarketName, marketsByNameRef]
  );

  const blotterTable = useBlotterTable<HydratedMarketCredential>({
    dataObservable,
    rowID: 'RowID',
    density: BlotterDensity.Comfortable,
    sort: persistedBlotterTable.initialSort,
    clientLocalFilter,
    onSortChanged: persistedBlotterTable.onSortChanged,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    columns: columnsWithMenu,
    onDoubleClickRow: handleEditCredential,
    getContextMenuItems: blotterTableMenu.getContextMenuItems,
    selection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  });

  const handleExport = useCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Credentials',
      }),
    });
  }, [blotterTable, mixpanel]);

  const showInstructions = selectedMarket && !selectedCredential;
  const formControlSize =
    editCredentialDrawer.isOpen || addCredentialDrawer.isOpen ? FormControlSizes.Small : FormControlSizes.Default;
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  return (
    <>
      <Panel>
        <PanelHeader>
          <h2>{showInstructions ? 'Instructions' : 'Credentials'}</h2>
          {!showInstructions && (
            <PanelActions>
              <Box ref={filtersContainerRef} />
              <Button startIcon={IconName.DocumentDownload} onClick={handleExport} size={formControlSize}>
                Export CSV
              </Button>
              {canEditCredential && (
                <>
                  <Divider orientation="vertical" />
                  <Button
                    startIcon={IconName.Plus}
                    variant={ButtonVariants.Positive}
                    onClick={handleAddCredential}
                    data-testid="add-credential-button"
                    size={formControlSize}
                  >
                    Add Credential
                  </Button>
                </>
              )}
            </PanelActions>
          )}
        </PanelHeader>
        <PanelContent w="100%" position="relative" overflow="hidden">
          <Box position="relative" h="100%">
            <Flex
              w="100%"
              h="100%"
              flexDirection="column"
              style={{
                position: 'absolute',
                visibility: showInstructions ? 'hidden' : undefined,
                pointerEvents: showInstructions ? 'none' : undefined,
              }}
            >
              <BlotterTableFilters
                {...filterBuilderAccordion}
                {...blotterTable.blotterTableFiltersProps}
                size={formControlSize}
              />
              <BlotterTable {...blotterTable} />
            </Flex>
          </Box>
          {showInstructions && (
            <Box overflow="hidden" w="100%" h="100%" position="absolute" pr="spacingLarge" pb="spacingLarge">
              <OnboardingGuide
                selectedMarket={selectedMarket}
                guide={selectedMarketConfig?.guide}
                externalIP={externalIP}
                formControlSize={FormControlSizes.Default}
              />
            </Box>
          )}
        </PanelContent>
      </Panel>
      <AddCredentialDrawer
        {...addCredentialDrawer}
        marketCredentials={marketCredentials}
        marketConfigs={marketConfigs}
        selectedMarket={selectedMarket}
        externalIP={externalIP}
        onSaved={handleOnSaved}
        onMarketSelected={handleMarketSelected}
        data-testid="add-credential-drawer"
      />
      <EditCredentialDrawer
        {...editCredentialDrawer}
        marketCredentials={marketCredentials}
        marketConfigs={marketConfigs}
        selectedCredential={selectedCredential!}
        externalIP={externalIP}
        onSaved={handleOnSaved}
        mktAccsWithFees={mktAccsWithFees}
        handleMarketAccountClick={handleOpenTradingControlsDrawer}
        data-testid="edit-credential-drawer"
      />
      <TradingControlsDetailsDrawerWrapper
        {...tradingDetailsDrawer}
        selectedEntity={selectedMarketAccount}
        marketCredentials={marketCredentials}
      />
      {blotterTableMenu.dialogComponents}
    </>
  );
});
