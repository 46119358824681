import {
  ACTION,
  Button,
  ButtonVariants,
  Dialog,
  FormControlSizes,
  FormGroup,
  HStack,
  Icon,
  IconName,
  Input,
  MixpanelEvent,
  MixpanelEventProperty,
  NoteVariant,
  NotificationVariants,
  Popover,
  Text,
  useDisclosure,
  useGlobalToasts,
  useMixpanel,
  useNotes,
  usePopoverState,
  VStack,
} from '@talos/kyoko';
import { OMSView } from 'components/OMS/OMSView';
import { useFeatureFlag, useRoleAuth } from 'hooks';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { useTradingSettings } from 'providers/TradingSettingsContext';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Route } from 'react-router-dom';
import { AutoHedgingButton } from '../../components/AutoHedging/AutoHedgingButton';
import { closeView, openView, selectView } from '../../components/OMS/OMSSlice';
import { orderFormViews } from '../../components/OMS/viewsWithTabs';
import { useOrderEntry } from '../../providers/AppConfigProvider';
import { useAppLayoutConfig } from '../../providers/AppLayoutConfig/AppLayoutConfigContext';
import { TradingControlsDialog } from '../Settings/TradingControls/Dialog/TradingControlsDialog';
import { TradingControlsDialogButton } from '../Settings/TradingControls/Dialog/TradingControlsDialogButton';
import { useTradingControlsDialog } from '../Settings/TradingControls/Dialog/useTradingControlsDialog';
import { LayoutMenu } from './LayoutMenu';
import { HeaderButton } from './styles';

const POPOVER_TARGET_STYLE = { height: '100%' };

export const HeaderButtons = memo(() => {
  const mixpanel = useMixpanel();
  const { enableFlexibleLayout } = useAppLayoutConfig();
  const { getConfig, disableTrading, enableTrading } = useOrgConfiguration();
  const modal = useDisclosure();
  const [isRequestLoading, setRequestLoading] = useState(false);
  const [confirm, setConfirm] = useState('');
  const { add: addToast } = useGlobalToasts();
  const { addNote, removeNote } = useNotes();
  const isShowingNotification = useRef(false);
  const isTradingDisabled = getConfig(OrgConfigurationKey.TradingDisableAll, '0') === '1';
  const { defaultOrderFormTab, enableSalesWorkflow } = useTradingSettings();

  const { isAuthorized } = useRoleAuth();
  const isKillSwitchRole = isAuthorized(ACTION.TOGGLE_KILLSWITCH);
  const tradingControlsDialog = useTradingControlsDialog();

  const dispatch = useAppStateDispatch();
  const view = useAppStateSelector(selectView);
  const { lastOrderFormUsed } = useOrderEntry();

  useEffect(() => {
    if (isTradingDisabled && !isShowingNotification.current) {
      addNote({
        id: 'trading-disabled',
        text: (
          <HStack gap="spacingDefault">
            <Icon color="colorTextPrimaryButton" icon={IconName.CloseCircle} />
            <Text color="colorTextPrimaryButton">Trading is currently disabled.</Text>
          </HStack>
        ),
        closable: false,
        variant: NoteVariant.Negative,
      });
      isShowingNotification.current = true;
    }
    if (!isTradingDisabled && isShowingNotification.current) {
      removeNote('trading-disabled', false);
      isShowingNotification.current = false;
    }
  }, [addNote, removeNote, isTradingDisabled]);

  const handleOrderToggle = useCallback(() => {
    const hasPermissionForOmsView = (view: OMSView) => {
      switch (view) {
        case OMSView.NewOrderForm:
          return isAuthorized(ACTION.SUBMIT_ORDER);
        case OMSView.RFQForm:
          return isAuthorized(ACTION.REQUEST_RFQ);
        case OMSView.SalesOrder:
          return isAuthorized(ACTION.SUBMIT_ORDER) && enableSalesWorkflow;
        case OMSView.SalesRFQForm:
          return isAuthorized(ACTION.REQUEST_RFQ) && enableSalesWorkflow;
        default:
          return false;
      }
    };

    const launchDefaultTab = () => {
      if (isAuthorized(ACTION.SUBMIT_ORDER)) {
        dispatch(openView(OMSView.NewOrderForm));
      } else {
        dispatch(openView(OMSView.RFQForm));
      }
    };

    if (orderFormViews.includes(view!)) {
      dispatch(closeView());
    } else {
      const tab = defaultOrderFormTab || lastOrderFormUsed;
      mixpanel.track(MixpanelEvent.OpenOrderForm);
      const hasPermissionForLastTab = hasPermissionForOmsView(tab);

      if (!hasPermissionForLastTab) {
        // user lost permission to their preferred tab / last viewed tab - need to open a safe default
        // since Order Forms toggle is visible it means user have at least one of the below permissions
        launchDefaultTab();
        return;
      }
      switch (defaultOrderFormTab || lastOrderFormUsed) {
        case OMSView.NewOrderForm:
          dispatch(openView(OMSView.NewOrderForm));
          return;
        case OMSView.RFQForm:
          dispatch(openView(OMSView.RFQForm));
          return;
        case OMSView.SalesOrder:
          dispatch(openView(OMSView.SalesOrder));
          return;
        case OMSView.SalesRFQForm:
          dispatch(openView(OMSView.SalesRFQForm));
          return;
        default:
          launchDefaultTab();
      }
    }
  }, [defaultOrderFormTab, dispatch, enableSalesWorkflow, isAuthorized, lastOrderFormUsed, mixpanel, view]);

  const handleClickOpenOrders = useCallback(() => {
    const shouldClose = view === OMSView.Cards;
    mixpanel.track(MixpanelEvent.ToggleActiveOrders, { [MixpanelEventProperty.Expanded]: !shouldClose });

    if (shouldClose) {
      dispatch(closeView());
      return;
    }

    dispatch(openView(OMSView.Cards));
  }, [dispatch, mixpanel, view]);

  const openKillSwitchModal = useCallback(() => {
    setConfirm('');
    modal.open();
  }, [modal]);

  const handleEnableTrading = useCallback(() => {
    setRequestLoading(true);
    return enableTrading()
      .then(() => {
        setRequestLoading(false);
        mixpanel.track(MixpanelEvent.KillSwitch, { [MixpanelEventProperty.Enabled]: true });
        addToast({
          timeout: 5000,
          variant: NotificationVariants.Positive,
          text: 'Enabled trading.',
          dismissable: true,
        });
      })
      .catch((e: ErrorEvent) => {
        setRequestLoading(false);
        addToast({
          timeout: 5000,
          variant: NotificationVariants.Negative,
          text: e?.message || 'Could not enable trading.',
          dismissable: true,
        });
      });
  }, [addToast, enableTrading, mixpanel]);

  const handleDisableTrading = useCallback(() => {
    setRequestLoading(true);
    return disableTrading()
      .then(() => {
        setRequestLoading(false);
        mixpanel.track(MixpanelEvent.KillSwitch, { [MixpanelEventProperty.Enabled]: false });
        addToast({
          timeout: 5000,
          variant: NotificationVariants.Positive,
          text: 'Disabled trading.',
          dismissable: true,
        });
      })
      .catch((e: ErrorEvent) => {
        setRequestLoading(false);
        addToast({
          timeout: 5000,
          variant: NotificationVariants.Negative,
          text: e?.message || 'Could not disable trading.',
          dismissable: true,
        });
      });
  }, [addToast, disableTrading, mixpanel]);

  const canViewOrderEntry = [
    ACTION.VIEW_ORDER_FORM,
    ACTION.VIEW_RFQ_FORM,
    ACTION.VIEW_SALES_ORDER_FORM,
    ACTION.VIEW_SALES_RFQ_FORM,
  ].some(isAuthorized);

  const viewOrdersAuthorized = isAuthorized(ACTION.VIEW_ORDER);
  const { enableAutoHedging } = useFeatureFlag();

  const layoutMenu = usePopoverState({
    placement: 'bottom-end',
    closeOnClickOutside: true,
    noPaddingAndBorder: true,
    onOpen: () => {
      mixpanel.track(MixpanelEvent.OpenLayoutMenu);
    },
  });

  return (
    <>
      {enableAutoHedging && <AutoHedgingButton />}
      {isKillSwitchRole && !tradingControlsDialog.enabled && (
        <HStack px="spacingDefault">
          <Button variant={ButtonVariants.Negative} size={FormControlSizes.Tiny} onClick={() => openKillSwitchModal()}>
            {!isTradingDisabled ? 'Disable' : 'Re-enable'} Trading
          </Button>
        </HStack>
      )}
      <TradingControlsDialogButton {...tradingControlsDialog} />
      {canViewOrderEntry && (
        <HeaderButton
          border="left"
          onClick={handleOrderToggle}
          size={FormControlSizes.Small}
          variant={!!view && orderFormViews.includes(view) ? ButtonVariants.Priority : ButtonVariants.Default}
          startIcon={IconName.BookOpen}
          data-testid="header-button-order-form"
        >
          Order Form
        </HeaderButton>
      )}
      {viewOrdersAuthorized && (
        <HeaderButton
          border="left"
          onClick={handleClickOpenOrders}
          size={FormControlSizes.Small}
          variant={view === OMSView.Cards ? ButtonVariants.Priority : ButtonVariants.Default}
          startIcon={IconName.Collection}
          data-testid="header-button-orders"
        >
          Orders
        </HeaderButton>
      )}
      <Route path="/trading">
        {enableFlexibleLayout && (
          <Popover {...layoutMenu} targetStyle={POPOVER_TARGET_STYLE}>
            <HeaderButton
              border="left"
              data-testid="layout-menu-button"
              startIcon={IconName.Template}
              variant={layoutMenu.isOpen ? ButtonVariants.Priority : ButtonVariants.Default}
            />
            <LayoutMenu />
          </Popover>
        )}
      </Route>
      {isTradingDisabled ? (
        <Dialog
          variant={ButtonVariants.Positive}
          showClose={true}
          {...modal}
          confirmLabel="Enable Trading"
          headerIcon={IconName.CheckCircleSolid}
          title="Enable Trading"
          onConfirm={handleEnableTrading}
          confirmDisabled={isRequestLoading}
          width={500}
        >
          <VStack gap="spacingComfortable" alignItems="flex-start" w="100%">
            <Text color="colorTextPrimaryButton" textAlign="left" w="100%">
              You are about to enable trading for all users. <br /> Please confirm the action.
            </Text>
          </VStack>
        </Dialog>
      ) : (
        <Dialog
          variant={ButtonVariants.Negative}
          showClose={true}
          {...modal}
          confirmLabel="Disable Trading & Cancel All Orders"
          headerIcon={IconName.ExclamationSolid}
          title="Disable Trading"
          confirmDisabled={confirm !== 'confirm' || isRequestLoading}
          onConfirm={handleDisableTrading}
          width={500}
        >
          <VStack gap="spacingComfortable" alignItems="flex-start">
            <Text color="colorTextPrimaryButton" textAlign="left">
              You are about to cancel all open orders and disable trading for all users. <br /> Please confirm the
              action.
            </Text>
            <VStack w="100%">
              <FormGroup label="To confirm, type “confirm” below:" w="100%">
                <Input value={confirm} autoComplete="off" onChange={e => setConfirm(e.target.value)} />
              </FormGroup>
            </VStack>
          </VStack>
        </Dialog>
      )}
      <TradingControlsDialog {...tradingControlsDialog} />
    </>
  );
});
